import { queryCache } from 'data/cache';
import { GoogleProfileQuery } from 'data/hooks/useGoogleProfile';
import * as googleAuthClient from './google-axios-client';
import { Token } from './token';

type AuthenticatedUser = {
  name: string;
  imageUrl: string;
  email: string;
};

let user: AuthenticatedUser | undefined;

function getUserData() {
  return user;
}

function setUser(userInfo?: UserInfo) {
  if (userInfo) {
    user = {
      name: userInfo.name,
      email: userInfo.email,
      imageUrl: userInfo.picture,
    };
  }
}

async function getAndSetUserInfo(
  accessToken: string,
): Promise<AuthenticatedUser | undefined> {
  const response = await googleAuthClient.getUserInfo(accessToken);
  if (response?.data) {
    const userInfo = response?.data as UserInfo;
    setUser(userInfo);
    return user;
  }
  return undefined;
}

function getAuthData() {
  if (!user || !user.email) return null;
  return {
    user,
  };
}

function saveProfileData(
  profile: NonNullable<ReturnType<typeof getAuthData>>['user'],
) {
  queryCache.setQueryData<GoogleProfileQuery>('googleProfile', profile, {
    cacheTime: Infinity,
  });
}

async function refreshToken(
  backendIdToken: string,
): Promise<RefreshTokens | undefined> {
  const { sessionId } = Token.get();
  if (sessionId) {
    const response = await googleAuthClient.refreshToken(
      backendIdToken,
      sessionId,
    );
    if (response?.data) {
      return response?.data as RefreshTokens;
    }
  }
  return undefined;
}

async function getTokens(code: string): Promise<CodeTokens | undefined> {
  const response = await googleAuthClient.authenticate(code);
  if (response?.data) {
    return response?.data as CodeTokens;
  }
  return undefined;
}

async function refreshAuth(): Promise<boolean> {
  const tokens = Token.get();
  if (!tokens?.backendIdToken) {
    return false;
  }
  const newTokens = await refreshToken(tokens.backendIdToken);
  if (!newTokens) {
    return false;
  }
  const expirationInSeconds = Math.round(Date.now() / 1000);
  Token.set({
    token: newTokens.accessToken,
    idToken: newTokens.idToken,
    sessionId: newTokens.sessionId,
    expiresAt: (expirationInSeconds + newTokens.expiresAt) * 1000,
  });
  return true;
}

async function getScopes(): Promise<string[] | undefined> {
  const tokens = Token.get();
  if (tokens.token) {
    const response = await googleAuthClient.getTokenInfo(tokens.token);
    return (response?.data.scope as string)?.split(' ');
  }
  return undefined;
}

async function signOut() {
  const { backendIdToken, sessionId } = Token.get();
  if (backendIdToken && sessionId) {
    await googleAuthClient.revokeToken(backendIdToken, sessionId);
  }
}

type RefreshTokens = {
  accessToken: string;
  idToken: string;
  sessionId: string;
  expiresAt: number;
};
type CodeTokens = {
  scope: string;
  name: string;
  picture: string;
  email: string;
  backendIdToken: string;
} & RefreshTokens;

type UserInfo = {
  name: string;
  given_name: string;
  family_name: string;
  picture: string;
  email: string;
  email_verified: boolean;
  locale: string;
  hd: string;
};
export const Google = {
  user,
  getScopes,
  refreshToken,
  getTokens,
  setUser,
  refreshAuth,
  getUserData,
  getAndSetUserInfo,
  saveProfileData,
  signOut,
};
