import { useQuery } from 'react-query';

import { getMailMergePoll } from 'data/requests/mailMerge';

export const useMailMergePoll = (userId?: string, campaignId?: string) => {
  const { data, status } = useQuery(
    ['mail-merge-poll', userId, campaignId],
    getMailMergePoll,
    {
      enabled: !!userId && !!campaignId,
    },
  );
  return { poll: data, status };
};
