import styled from 'styled-components';

export const Container = styled.fieldset`
  display: flex;
  flex-direction: column;
  padding-bottom: 4px;

  & legend {
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.375rem;
    margin-bottom: 0.75rem;
  }
`;

export const InputContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const Content = styled.div`
  display: flex;
  gap: 0 1.5625rem;
  height: 2.5rem;
`;

export const Label = styled.label`
  color: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.375rem;
  margin-left: 0.5625rem;
`;
