import React, { FC, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import { useMe } from 'data/hooks/useMe';
import YammScreenshot from 'assets/images/yamm-screenshot.png';
import { ReactComponent as Arrows } from 'assets/images/arrows.svg';

import { Link } from '../styles';
import { Title, Text, Screenshot, YammLink, Button, Card } from './styles';
import { Props } from './types';

import {
  stepTwoLoad,
  stepTwoMarketplace,
  stepTwoContinue,
  stepTwoSkip,
  onboardingInstall,
} from '../events';
import { pushFunnel, pushGoToMarketplace } from '../../../../utils/dataLayer';

const StepTwo: FC<Props> = ({ handleNext, visited, changeVisited }) => {
  const { me } = useMe();
  const history = useHistory();
  useEffect(() => {
    onboardingInstall();
    stepTwoLoad();
    history.replace('get-started');
    pushFunnel('Install YAMM', '2');
  }, []);

  const handleImageClick = useCallback(() => {
    stepTwoMarketplace('image');
    pushGoToMarketplace();
    changeVisited(true);
  }, [changeVisited]);

  const handleButtonClick = useCallback(() => {
    stepTwoMarketplace('button');
    pushGoToMarketplace();
    changeVisited(true);
  }, [changeVisited]);

  const handleNextStep = useCallback(() => {
    stepTwoContinue();
    handleNext();
  }, [handleNext]);

  const ready = (me && me.installationTimestamp) || visited;

  return (
    <>
      <Card>
        <Title>1. Install the YAMM add-on</Title>
        <Text>
          YAMM is a Google Sheets add-on. From the Google Marketplace, click on
          <b>&nbsp;Install</b>, select your Google account and grant the
          required permissions.
        </Text>
        <YammLink onClick={handleImageClick}>
          <Screenshot>
            <img src={YammScreenshot} alt="yamm" />
            <Arrows />
          </Screenshot>
        </YammLink>
        <YammLink onClick={handleButtonClick}>
          <Button className={!visited ? 'pulse pulse--marketplace' : ''}>
            Go to Marketplace
          </Button>
        </YammLink>
        <Title>2. Once installation is done, return to this window</Title>
        <Text>Then click Continue.</Text>
        <Tooltip
          title={!ready ? 'You must have YAMM installed to continue.' : ''}
          placement="right"
          arrow
        >
          <Button
            className={visited ? 'pulse pulse--continue' : ''}
            onClick={handleNextStep}
            disabled={!ready}
          >
            Continue
          </Button>
        </Tooltip>
      </Card>
      <Link onClick={stepTwoSkip} to="/">
        Skip the tutorial
      </Link>
    </>
  );
};

export default StepTwo;
