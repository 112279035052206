import styled, { keyframes } from 'styled-components';
import DefaultButton from 'components/Button';
import { from } from 'styles/media';
import addAlpha from 'utils/addAlpha';
import DefaultRadioInput from 'components/RadioInput';
import { ReactComponent as DefaultInfoIcon } from '../../assets/images/icons/info.svg';

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  box-shadow: 0 0.125rem 0.5rem rgba(152, 152, 152, 0.2);
  display: flex;
  flex-direction: column;
  padding: 0.875rem 0.75rem;

  > * + *:not(:last-child) {
    margin-top: 8px;
  }

  ${from.tablet} {
    min-height: 4.0625rem;
    align-items: center;
    display: grid;
    flex-direction: unset;
    grid-column-gap: 1rem;
    grid-template-columns: 8rem 10rem 16rem 10.5rem 8rem;
    padding: 1rem;

    > * + *:not(:last-child) {
      margin-top: 0;
    }
  }
`;

export const CurrentContainer = styled(Container)`
  ${from.tablet} {
    grid-template-columns: 10.625rem 11.875rem 10rem 9.5rem 10.5rem;
  }

  .bold {
    font-weight: 500;
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${from.tablet} {
    display: block;
  }
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.625rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${from.tablet} {
    font-size: 0.875rem;
  }
`;

export const Loading = keyframes`
  to {
    background-position-x: 5rem;
  }
`;

export const PlaceholderText = styled.span`
  height: 1.625rem;
  display: block;
  border-radius: 0.25rem;
  background-image: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.gray1} 20%,
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.6)} 40%,
    ${({ theme }) => theme.colors.gray1} 85%
  );
  background-position: -5rem;
  animation: ${Loading} 1500ms linear infinite alternate;
`;

export const PlaceholderButton = styled(PlaceholderText)`
  height: 2rem;
`;

export const Text = styled.div`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.875rem;
  line-height: 1.375rem;
  display: flex;
`;

export const BoldText = styled(Text)`
  font-weight: 500;
`;

export const MonthlyPrice = styled.span`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 25.6px */
`;

export const MonthlyPriceContainer = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  gap: 1px;
`;

export const AnnuallyBillingText = styled(Text)`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
  font-weight: 400;
  font-size: 12px;
`;

export const Color = styled.span`
  color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.75)};
  font-weight: 400;
  flex-shrink: 0;
`;

export const Bottom = styled.div`
  align-items: center;
  border-top: 0.0625rem solid
    ${({ theme }) => addAlpha(theme.colors.gray2, 0.75)};
  display: flex;
  justify-content: flex-end;
  margin-top: 0.875rem;
  padding-top: 0.875rem;

  & > *:not(:last-child) {
    margin-right: 1rem;
  }
`;

export const BottomPrice = styled.div`
  flex: 1;
`;

export const IconedManagePlanContainer = styled.div`
  display: grid;
  justify-content: flex-end;
  grid-gap: 0.5rem;
  grid-template-columns: 2rem 8rem;
`;

export const Button = styled(DefaultButton)`
  width: 8rem;

  ${from.tablet} {
    justify-self: flex-end;
  }
`;

export const Link = styled.a`
  border-radius: 0.25rem;
  border: 0.0625rem solid ${({ theme }) => theme.colors.orange600};
  color: ${({ theme }) => theme.colors.orange600};
  cursor: pointer;
  display: inline-flex;
  font-family: ${({ theme }) => theme.fonts.inter};
  font-size: 0.875rem;
  font-weight: ${({ theme }) => theme.weights.medium};
  justify-content: center;
  line-height: 1.375rem;
  padding: 0.25rem 1rem;
  text-decoration: none;
  transition: all 0.2s ease;

  :hover:not(:disabled) {
    background-color: ${({ theme }) => theme.colors.orange500};
    color: ${({ theme }) => theme.colors.white};
  }

  :focus {
    background-color: ${({ theme }) => theme.colors.orange800};
    color: ${({ theme }) => theme.colors.white};
    outline: none;
  }

  :disabled {
    border-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    cursor: not-allowed;
  }
`;

export const InfoIcon = styled(DefaultInfoIcon)`
  width: 14px;
  height: 14px;
  color: ${({ theme }) => theme.colors.suvaGrey};
  cursor: pointer;
`;

export const RecipientsContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const RadioInput = styled(DefaultRadioInput)`
  flex: 1;
`;
