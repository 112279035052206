export const PRICES: { [key: string]: { [key: string]: any } } = {
  EUR: {
    YEARLY: {
      PERSONAL: 25,
      PROFESSIONAL: 50,
      TEAM_5: 150,
      TEAM_10: 240,
      TEAM_25: 500,
      TEAM_50: 1000,
      TEAM_100: 2000,
    },
    MONTHLY: {
      PERSONAL: 10,
      PROFESSIONAL: 20,
      TEAM_5: 50,
      TEAM_10: 80,
      TEAM_25: 167,
      TEAM_50: 333,
      TEAM_100: 667,
    },
  },
  USD: {
    YEARLY: {
      PERSONAL: 25,
      PROFESSIONAL: 50,
      TEAM_5: 150,
      TEAM_10: 240,
      TEAM_25: 500,
      TEAM_50: 1000,
      TEAM_100: 2000,
    },
    MONTHLY: {
      PERSONAL: 10,
      PROFESSIONAL: 20,
      TEAM_5: 50,
      TEAM_10: 80,
      TEAM_25: 167,
      TEAM_50: 333,
      TEAM_100: 667,
    },
  },
  INR: {
    YEARLY: {
      PERSONAL: 1999,
      PROFESSIONAL: 3999,
      TEAM_5: 12000,
      TEAM_10: 19000,
      TEAM_25: 40000,
      TEAM_50: 80000,
      TEAM_100: 160000,
    },
    MONTHLY: {
      PERSONAL: Infinity,
      PROFESSIONAL: Infinity,
      TEAM_5: Infinity,
      TEAM_10: Infinity,
      TEAM_25: Infinity,
      TEAM_50: Infinity,
      TEAM_100: Infinity,
    },
  },
};
