import React, { useCallback, useEffect, useMemo } from 'react';
import {
  useGlobalFilter,
  useSortBy,
  useTable,
  usePagination,
  useFilters,
} from 'react-table';
import {
  dateFilterFunction,
  getDefaultSort,
  globalFilterFunction,
  useSticky,
} from 'components/DesktopTable/logic';
import StickySentinel from 'components/StickySentinel';
import Pagination from 'components/DesktopTable/Pagination';
import Empty from 'components/DesktopTable/Empty';
import SorterSelect from 'components/SorterSelect';
import { sortDate } from 'utils/sortDate';
import { DateFilter } from 'components/DesktopTable/types';
import { getAccessor } from 'utils/getAccessor';
import { MobileTableContainer, FilterContainer, Container } from './styles';
import { Props } from './types';
import Placeholder from './Placeholder';

function MobileTable<DataType extends { id: string | number }>({
  data,
  getData,
  setPage,
  columns,
  row,
  itemsPerPage = 25,
  loading,
  empty,
  notFound,
  defaultDateFilter = 'LAST_30_DAYS',
  disableFilter = false,
  filter: FilterComponent,
  exporter: ExportComponent,
  ...props
}: Props<DataType>) {
  const defaultSorted = useMemo(() => getDefaultSort(columns), [columns]);

  const dateColumn = useMemo(
    () => getAccessor(columns.find((column) => column.filter === 'unix')),
    [columns],
  );

  const filters = useMemo(
    () =>
      !disableFilter && dateColumn
        ? [{ id: dateColumn, value: defaultDateFilter }]
        : [],
    [dateColumn, defaultDateFilter],
  );

  const {
    state: { pageIndex, globalFilter, sortBy, filters: filterState },
    pageCount,
    canPreviousPage,
    canNextPage,
    gotoPage,
    previousPage,
    nextPage,
    headers,
    page,
    setFilter,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      globalFilter: globalFilterFunction,
      initialState: {
        pageSize: itemsPerPage,
        sortBy: defaultSorted ? [defaultSorted] : [],
        filters,
      },
      sortTypes: {
        datetime: sortDate,
      },
      filterTypes: {
        unix: dateFilterFunction,
      },
      disableMultiSort: true,
      autoResetSortBy: false,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      autoResetPage: false,
      disableFilters: disableFilter,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    if (setPage) {
      setPage(page.map(({ original }) => original));
    }
  }, [pageIndex, pageCount, globalFilter, sortBy, filterState]);

  const rows = useMemo(() => {
    return page.map((rowData, index) => {
      return row(rowData.original as DataType, index);
    });
  }, [page, row]);

  const selectableHeaders = useMemo(() => {
    return headers
      .filter((header) => header.canSort)
      .map((header) => ({
        value: header.id,
        label: header.Header ? header.Header.toString() : header.id,
        desc: header.isSortedDesc,
      }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headers, sortBy]);

  const handleSortChange = useCallback(
    (option: { label?: string; value: any }) => {
      const foundHeader = headers.find((header) => header.id === option.value);
      if (!foundHeader) return;
      const direction =
        foundHeader.isSortedDesc === undefined
          ? false
          : !foundHeader.isSortedDesc;
      foundHeader.toggleSortBy(direction, false);
    },
    [headers],
  );

  const defaultSort = useMemo(() => {
    const foundSorted = columns.find((column) => column.defaultSorted);
    if (!foundSorted) return undefined;
    return {
      value: foundSorted.accessor,
      label: foundSorted.Header?.toString(),
    };
  }, [columns]);

  const sortValue = useMemo(() => {
    if (!Array.isArray(sortBy) || !sortBy[0]) return undefined;
    const firstRule = sortBy[0];
    const header = selectableHeaders.find(
      (item) => item.value === firstRule.id,
    );
    return header;
  }, [sortBy, selectableHeaders]);

  const dateFilter = useCallback(
    (value: DateFilter) => {
      if (!dateColumn) return;
      setFilter(dateColumn, value);
    },
    [dateColumn],
  );

  const { isStuck, onSticky } = useSticky();
  const isLoading = loading && page.length === 0;
  const isEmpty =
    !loading && page.length === 0 && (globalFilter || '').length === 0;
  const isNotFound =
    !loading && page.length === 0 && (globalFilter || '').length > 0;

  return (
    <>
      <StickySentinel onSticky={onSticky} />
      {FilterComponent && (
        <FilterContainer $isStuck={isStuck}>
          <FilterComponent
            onChange={setGlobalFilter}
            onDateFilterChange={dateFilter}
            currentDateFilter={defaultDateFilter}
            goToPage={gotoPage}
          />
          <Container>
            <SorterSelect
              defaultValue={defaultSort}
              options={selectableHeaders}
              onChange={handleSortChange}
              value={sortValue}
            />
            {ExportComponent ? (
              <ExportComponent data={data} getData={getData} />
            ) : null}
          </Container>
        </FilterContainer>
      )}
      {isLoading ? <Placeholder animated /> : null}
      {isEmpty || isNotFound ? (
        <Empty
          foreground={isNotFound ? notFound : empty}
          background={<Placeholder />}
        />
      ) : null}
      {!isLoading && !isEmpty && !isNotFound && (
        <>
          <MobileTableContainer {...props}>{rows}</MobileTableContainer>
          <Pagination
            current={pageIndex}
            pages={pageCount}
            hasNext={canNextPage}
            hasPrevious={canPreviousPage}
            goToPage={gotoPage}
            nextPage={nextPage}
            previousPage={previousPage}
          />
        </>
      )}
    </>
  );
}

export default MobileTable;
