import { useQuery } from 'react-query';
import { normalizeCampaign } from 'model/campaign';
import { getMailMerges, GetMailMergesDto } from 'data/requests/mailMerges';
import { subMonths, format } from 'date-fns';
import { useCallback } from 'react';

export const useMailMerges = (userId?: string) => {
  const params: GetMailMergesDto = {
    'lastTimeUsed[gte]': format(subMonths(new Date(), 12), 'yyyy-MM-dd'),
    excludeTracking: true,
  };
  const { data, status } = useQuery(
    ['mail-merges', userId, params],
    getMailMerges,
    {
      enabled: !!userId,
    },
  );
  const mailMerges = (data || []).map((mailMerge) =>
    normalizeCampaign(mailMerge, params.excludeTracking),
  );

  const fullParams: GetMailMergesDto = {
    ...params,
    excludeTracking: false,
  };
  const { refetch } = useQuery(
    ['mail-merges', userId, fullParams],
    getMailMerges,
    {
      enabled: false, // This will be only fetch manually
    },
  );
  const getFullMailMerges = useCallback(async () => {
    if (!userId) return [];
    const input = await refetch();
    return (input || []).map((mailMerge) => normalizeCampaign(mailMerge));
  }, [refetch, userId, fullParams]);

  return { mailMerges, getMailMerges: getFullMailMerges, status };
};
