import styled from 'styled-components';
import { ReactComponent as DefaultIcon } from 'assets/images/icons/chevron-down.svg';
import addAlpha from 'utils/addAlpha';

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.gray3};
  position: relative;
  width: 8rem;
`;

export const Component = styled.select`
  appearance: none;
  background-color: transparent;
  border: 1px solid #dedcdb;
  border-radius: 4px;
  color: inherit;
  font-family: Inter;
  font-size: 0.875rem;
  line-height: 1.375rem;
  padding: 4px 6px;
  padding-right: 1.25rem;
  width: 100%;

  &:disabled {
    color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
  }

  &:hover:not(:disabled) {
    cursor: pointer;
  }
`;

export const Icon = styled(DefaultIcon)`
  color: inherit;
  height: 1.25rem;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1.25rem;
`;
