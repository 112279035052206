import currencyjs from 'currency.js';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';
import { LogicProps, Props } from './types';
import { formatPrice } from '../../utils/priceFormat';
import { useQuotaConditionsModalTrigger } from '../../containers/Common/WrongPlanChoice/QuotaConditionsModal/useQuotaConditionsModalTrigger';

export const getFormattedValue = (value: number, currency: Props['currency']) =>
  formatPrice(value, currency?.toUpperCase() || 'USD');

type FormattedAmountParams = Pick<
  Props,
  'amount' | 'currency' | 'taxRate' | 'taxRateIncluded'
>;

export const useFormattedAmounts = ({
  amount,
  currency,
  taxRate,
  taxRateIncluded,
}: FormattedAmountParams) => {
  const amountFormatted = useMemo(() => getFormattedValue(amount, currency), [
    amount,
    currency,
  ]);

  const taxes = useMemo(() => {
    return taxRate !== undefined && taxRate !== null
      ? currencyjs(amount).multiply(taxRateIncluded ? 0 : taxRate / 100)
      : undefined;
  }, [taxRate, taxRateIncluded, amount, currency]);

  const taxesFormatted = useMemo(
    () => (taxes ? getFormattedValue(taxes.value, currency) : undefined),
    [taxes, currency],
  );

  const totalWithTaxes = useMemo(
    () =>
      taxes !== undefined
        ? getFormattedValue(taxes.add(amount).value, currency)
        : undefined,
    [taxes, amount, currency],
  );

  return {
    amountFormatted,
    taxesFormatted,
    totalWithTaxes,
  };
};

export const useFormattedBillingInterval = (
  billingInterval: string | undefined,
) => {
  const formattedBillingText =
    billingInterval === 'MONTHLY' ? 'Billed monthly' : 'Billed annually';
  return formattedBillingText;
};

const useLogic = ({
  nameSeats,
  nameBillingInterval,
  onSeatsChange,
  onBillingIntervalChange,
}: LogicProps) => {
  const { trigger } = useQuotaConditionsModalTrigger();

  const [, meta] = useField<number>(nameSeats);
  const [{ value }] = useField<string>(nameBillingInterval);
  const handleSeatsChange = useCallback(
    (seats: number) => {
      if (onSeatsChange) {
        onSeatsChange(seats);
      }
    },
    [onSeatsChange],
  );

  const handleBillingIntervalChange = useCallback(
    (billingInterval: string) => {
      if (onBillingIntervalChange) {
        onBillingIntervalChange(billingInterval);
      }
    },
    [onBillingIntervalChange],
  );

  return {
    //  seats: value,
    //  billingInterval: nameBillingInterval,
    seats: meta.value,
    billingInterval: value,
    handleSeatsChange,
    handleBillingIntervalChange,
    triggerQuotaModal: trigger,
  };
};

export default useLogic;
