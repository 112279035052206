import { client } from 'data/client';
import { Input } from 'model/campaign';
import {
  PollResult,
  Input as PollInput,
  normalizePoll,
  normalizePollResults,
} from 'model/poll';
import { Token } from '../context/AuthContext/token';

type GetCampaignResponse = Input;

export async function getMailMerge(
  key: string,
  userId: string,
  campaignId: string,
) {
  const { idToken } = Token.get();
  const { data } = await client.get<GetCampaignResponse>(
    `/users/${userId}/campaigns/${campaignId}`,
    {
      headers: {
        'X-ID-Token': `Bearer ${idToken}`,
      },
    },
  );
  return data;
}

type GetCampaignPollResponse = {
  items: PollInput[];
};

type GetCampaignPollResultResponse = {
  items: PollResult[];
};

export async function getMailMergePoll(
  key: string,
  userId: string,
  campaignId: string,
) {
  try {
    const { idToken } = Token.get();
    const {
      data: resultData,
    } = await client.get<GetCampaignPollResultResponse>(
      `/users/${userId}/campaigns/${campaignId}/pollResults`,
      {
        headers: {
          'X-ID-Token': `Bearer ${idToken}`,
        },
      },
    );
    if (resultData.items && resultData.items.length > 0) {
      return (
        resultData.items &&
        resultData.items.length > 0 &&
        normalizePollResults(resultData.items)
      );
    }
  } catch (e) {
    // normal in rollout period, do nothing
  }

  const { data: responseData } = await client.get<GetCampaignPollResponse>(
    `/users/${userId}/campaigns/${campaignId}/pollResponses`,
  );
  return (
    responseData.items &&
    responseData.items.length > 0 &&
    normalizePoll(responseData.items)
  );
}
