import ProgressBar from 'components/ProgressBar';
import React, { FC } from 'react';

import { Container } from './styles';

import { List, Item, ResultsLabel, Progress, Text } from '../styles';

import { Props } from './types';
import { useConnect } from './connect';

const MailResults: FC<Props> = ({ campaign }) => {
  const {
    delivered,
    clicked,
    opened,
    responded,
    bounced,
    unsubscribed,
    percentages,
    lastUpdated,
  } = useConnect({ campaign });
  return (
    <Container>
      <List>
        <Item>
          <ResultsLabel>
            Delivered
            <Progress>
              <span>{percentages.delivered}</span>% (<span>{delivered}</span>)
            </Progress>
          </ResultsLabel>
          <ProgressBar variant="lightBlue" progress={percentages.delivered} />
        </Item>
        <Item>
          <ResultsLabel>
            Opened
            <Progress>
              <span>{percentages.opened}</span>% (<span>{opened}</span>)
            </Progress>
          </ResultsLabel>
          <ProgressBar variant="purple" progress={percentages.opened} />
        </Item>
        <Item>
          <ResultsLabel>
            Clicked
            <Progress>
              <span>{percentages.clicked}</span>% (<span>{clicked}</span>)
            </Progress>
          </ResultsLabel>
          <ProgressBar variant="blue" progress={percentages.clicked} />
        </Item>
        <Item>
          <ResultsLabel>
            Responded{' '}
            <Progress>
              <span>{percentages.responded}</span>% (<span>{responded}</span>)
            </Progress>
          </ResultsLabel>
          <ProgressBar variant="green" progress={percentages.responded} />
        </Item>
        <Item>
          <ResultsLabel>
            Bounced&nbsp;
            <Progress>
              <span>{percentages.bounced}</span>% (<span>{bounced}</span>)
            </Progress>
          </ResultsLabel>
          <ProgressBar variant="yellow" progress={percentages.bounced} />
        </Item>
        <Item>
          <ResultsLabel>
            Unsubscribed
            <Progress>
              <span>{percentages.unsubscribed}</span>% (
              <span>{unsubscribed}</span>)
            </Progress>
          </ResultsLabel>
          <ProgressBar variant="red" progress={percentages.unsubscribed} />
        </Item>
      </List>
      <Text>
        Last update: <span>{lastUpdated}</span>. <br />
        Open your tracking report in Google Sheets to refresh.
      </Text>
    </Container>
  );
};

export default MailResults;
