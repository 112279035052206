import React from 'react';
import Bold from 'components/Bold';
import { formatMonthDay } from 'utils/dates';
import { Column } from 'components/DesktopTable/types';
import { Campaign } from 'model/campaign';
import { percentage } from 'utils/percentage';
import DateTimeCell from 'components/DesktopTable/DateTimeCell';
import { Progress, SheetLink, SheetIcon, CampaignLink } from '../../styles';
import { Placeholder } from './styles';

export const getColumns = ({ url }: { url: string }) =>
  [
    {
      Header: 'Name',
      accessor: 'title',
      width: 228,
      Cell: ({ value, row }) => (
        <CampaignLink
          to={`${url}/${(row.original as Campaign).id}`}
          title={value.length > 28 ? value : undefined}
        >
          <Bold style={{ fontVariant: 'tabular-nums' }}>{value}</Bold>
        </CampaignLink>
      ),
    },
    {
      Header: 'Date',
      accessor: 'lastTimeUsed',
      width: 60,
      filter: 'unix',
      sortType: 'datetime',
      defaultSorted: 'desc',
      Cell: ({ value }) => (
        <DateTimeCell value={value} formatFunction={formatMonthDay} />
      ),
    },
    {
      Header: 'Sent',
      accessor: 'trackingInfos.sent',
      alignment: 'right',
      width: 50,
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ value, row }) => {
        if (!(row.original as Campaign).hasTrackingData) {
          return <Placeholder />;
        }
        return (
          <>{value !== undefined ? value.toLocaleString('de-DE') : 'N/A'}</>
        );
      },
    },
    {
      Header: 'Opened',
      accessor: 'trackingInfos.opened',
      width: 100,
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ value, row }) => {
        if (!(row.original as Campaign).hasTrackingData) {
          return <Placeholder />;
        }
        const base =
          (row.original as Campaign).trackingInfos.sent -
          (row.original as Campaign).trackingInfos.bounced;
        const asPercentage = percentage(value, base);
        return (
          <>
            <Progress progress={asPercentage} variant="purple" /> {asPercentage}
            %
          </>
        );
      },
    },
    {
      Header: 'Clicked',
      accessor: 'trackingInfos.clicked',
      width: 100,
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ value, row }) => {
        if (!(row.original as Campaign).hasTrackingData) {
          return <Placeholder />;
        }
        const base =
          (row.original as Campaign).trackingInfos.sent -
          (row.original as Campaign).trackingInfos.bounced;
        const asPercentage = percentage(value, base);
        return (
          <>
            <Progress progress={asPercentage} variant="blue" /> {asPercentage}%
          </>
        );
      },
    },
    {
      Header: 'Sheet',
      accessor: 'spreadsheetLink',
      alignment: 'right',
      disableGlobalFilter: true,
      disableSortBy: true,
      Cell: ({ value }) => (
        <SheetLink href={value} target="_blank" rel="noopener noreferrer">
          <SheetIcon />
        </SheetLink>
      ),
    },
  ] as Column[];
