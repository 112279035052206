import { NewPlan } from 'model/plan';

export function getPlanQuota(plan: NewPlan, freeUserQuota?: number): number {
  if (plan === 'FREE') {
    return freeUserQuota ?? 50;
  }

  if (plan === 'PERSONAL') {
    return 400;
  }

  return 1500;
}
