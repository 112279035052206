import styled from 'styled-components';
import DefaultSelect from 'react-select';
import { ReactComponent as DefaultIcon } from 'assets/images/icons/chevron-down.svg';
import addAlpha from 'utils/addAlpha';

export const Select = styled(DefaultSelect)`
  color: ${({ theme }) => theme.colors.blue900};

  & .billing-interval-selector {
    &__control {
      border: 0;
      width: max-content;
      min-height: unset;
      box-sizing: content-box;
      cursor: pointer;

      &--is-focused {
        box-shadow: none;
      }
      &--is-focused .billing-interval-selector__single-value,
      &:hover .billing-interval-selector__single-value {
        border-color: ${({ theme }) => theme.colors.blue200};
      }
    }

    &__value-container {
      padding: 0;
    }

    &__single-value {
      border-bottom: 1px dashed ${({ theme }) => theme.colors.blue100};
      margin-left: 0;
      color: inherit;
      text-overflow: unset;
      overflow: unset;
      max-width: none;
      position: initial;
      top: unset;
      transform: none;
    }

    &__single-value {
    }

    &__menu {
      box-shadow: 0rem 0.25rem 0.75rem rgba(107, 124, 147, 0.2);
      color: inherit;
      margin-top: 0.5rem;
      overflow: hidden;
      width: 9.5rem;
    }

    &__menu-list {
      padding: 0;
    }

    &__option {
      background: ${({ theme }) => theme.colors.white};
      color: ${({ theme }) => theme.colors.gray3};
      cursor: pointer;
      font-size: 0.875rem;
      line-height: 1.375rem;
      padding: 0.75rem;

      &:hover,
      &--is-focused {
        background: ${({ theme }) => theme.colors.gray1};
      }

      &:active {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.gray4};
        font-weight: 500;
      }

      &--is-selected {
        background: ${({ theme }) => addAlpha(theme.colors.gray1, 0.8)};
      }
    }
  }
`;

export const Icon = styled(DefaultIcon)`
  color: inherit;
  height: 1.25rem;
  margin-right: 0.4rem;
  width: 1.25rem;
`;
