import React from 'react';
import { PriceCardProps } from '../../../components/PriceCard/types';
import { PRICES } from '../../../data/prices';

export const PersonalPriceCardDataYear: PriceCardProps = {
  title: 'Personal',
  target: '@gmail.com account',
  price: PRICES.USD.YEARLY.PERSONAL,
  currency: '$',
  billingPeriod: 'year',
  recipients: 400,
  subscribeHref: '/settings/billing?plan=PERSONAL&billingInterval=YEARLY',
  tooltipContent: (
    <>
      You can only send emails with personalized attachments to 100 recipients /
      day. <i>Click to learn more</i>
    </>
  ),
};

export const PersonalPriceCardDataMonth: PriceCardProps = {
  title: 'Personal',
  target: '@gmail.com account',
  price: PRICES.USD.MONTHLY.PERSONAL,
  currency: '$',
  billingPeriod: 'month',
  recipients: 400,
  subscribeHref: '/settings/billing?plan=PERSONAL&billingInterval=MONTHLY',
  tooltipContent: (
    <>
      You can only send emails with personalized attachments to 100 recipients /
      day. <i>Click to learn more</i>
    </>
  ),
};

export const ProfessionalPriceCardDataYear: PriceCardProps = {
  title: 'Professional',
  target: 'Google Workspace account',
  price: PRICES.USD.YEARLY.PROFESSIONAL,
  currency: '$',
  billingPeriod: 'year',
  recipients: 1500,
  subscribeHref: '/settings/billing?plan=PROFESSIONAL&billingInterval=YEARLY',
  tooltipContent: (
    <>
      This email quota is subject to certain conditions.{' '}
      <i>Click to learn more</i>
    </>
  ),
};

export const ProfessionalPriceCardDataMonth: PriceCardProps = {
  title: 'Professional',
  target: 'Google Workspace account',
  price: PRICES.USD.MONTHLY.PROFESSIONAL,
  currency: '$',
  billingPeriod: 'month',
  recipients: 1500,
  subscribeHref: '/settings/billing?plan=PROFESSIONAL&billingInterval=MONTHLY',
  tooltipContent: (
    <>
      This email quota is subject to certain conditions.{' '}
      <i>Click to learn more</i>
    </>
  ),
};
