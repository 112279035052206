/* eslint-disable no-param-reassign */
import axios from 'axios';
import { Token } from './context/AuthContext/token';

export const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_URL_BASE}`,
});

client.interceptors.request.use(async (config) => {
  const { token } = Token.get();
  // @ts-ignore
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

export const clientV2 = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}${process.env.REACT_APP_API_URL_BASE_V2}`,
});

clientV2.interceptors.request.use(async (config) => {
  const { token } = Token.get();
  // @ts-ignore
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});
