import { Input as CampaignInput, Campaign } from './campaign';
import { normalizeTrackingData } from './trackingData';

export type Input = Pick<CampaignInput, 'totalEmails' | 'trackingInfos'>;
export type Tracking = Record<string, Record<string, Input>>;

const enrichCampaign = (data: Campaign, input?: Input) => {
  const normalized: Campaign = {
    ...data,
    hasTrackingData: !!input,
    totalEmails: input?.totalEmails ?? data.totalEmails,
    trackingInfos: input?.trackingInfos
      ? normalizeTrackingData(input.trackingInfos)
      : data.trackingInfos,
  };

  if (input?.totalEmails) {
    normalized.trackingInfos.sent = input.totalEmails;
  }

  return normalized;
};

export const enrichCampaigns = (
  data: Campaign[] | undefined,
  trackingData?: Tracking,
) =>
  (data ?? []).map((mailMerge) =>
    enrichCampaign(
      mailMerge,
      trackingData && trackingData[mailMerge.spreadsheetId]
        ? trackingData[mailMerge.spreadsheetId][mailMerge.sheetId]
        : undefined,
    ),
  );
