import React, { memo } from 'react';
import { options } from './constants';
import useLogic from './logic';
import { Select, Icon } from './styles';
import { Props } from './types';

const BillingIntervalSelect = ({ name, onChange }: Props) => {
  const { handleChange, value } = useLogic({ name, onChange });

  return (
    <Select
      classNamePrefix="billing-interval-selector"
      components={{
        DropdownIndicator: () => <Icon />,
        IndicatorSeparator: () => null,
      }}
      isClearable={false}
      isSearchable={false}
      name={name}
      onChange={handleChange}
      options={options}
      value={options.find((option) => option.value === value)}
    />
  );
};

export default memo(BillingIntervalSelect);
