import React, { FC, useCallback } from 'react';
import DataView from 'components/DataView';
import DesktopTable from 'components/DesktopTable';
import Row from 'components/MobileTable/Row';
import MobileTable from 'components/MobileTable';
import { Campaign } from 'model/campaign';
import { RowLink } from 'components/DesktopTable/Rows/styles';
import testingVar from 'utils/testingVar';
import { Props } from './types';
import Search from './table/filter';
import Exporter from './table/exporter';
import NotFound from './table/notFound';
import { useConnect } from './connect';
import CampaignDetail from './CampaignDetail';
import Empty from './table/empty';

const SentMailMerges: FC<Props> = ({
  campaigns,
  getCampaigns,
  isMobile,
  isLoading,
  setPage,
}) => {
  const { columns, mailMergesTestingVar, url } = useConnect();
  const rowLink = useCallback(
    (props: { item: Campaign }) => (
      <RowLink tabIndex={-1} to={`${url}/${props.item.id}`} />
    ),
    [url],
  );
  return (
    <>
      <DataView>
        {isMobile ? (
          <MobileTable
            itemsPerPage={10}
            data={campaigns}
            getData={getCampaigns}
            setPage={setPage}
            columns={columns}
            loading={isLoading}
            row={(item) => (
              <Row
                data-test-id={testingVar(`table-row`)}
                key={item.id}
                url={`${url}/${item.id}`}
                name={item.title}
                sent={item.trackingInfos.sent}
                opened={item.trackingInfos.opened}
                clicked={item.trackingInfos.clicked}
                bounced={item.trackingInfos.bounced}
                date={item.lastTimeUsed}
              />
            )}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
          />
        ) : (
          <DesktopTable
            itemsPerPage={10}
            data={campaigns}
            getData={getCampaigns}
            setPage={setPage}
            loading={isLoading}
            columns={columns}
            rowLink={rowLink}
            empty={<Empty />}
            notFound={<NotFound />}
            filter={Search}
            exporter={Exporter}
          />
        )}
        {mailMergesTestingVar ? (
          <span data-test-id={mailMergesTestingVar} />
        ) : null}
      </DataView>
      <CampaignDetail />
    </>
  );
};

export default SentMailMerges;
