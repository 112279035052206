import { NewPlan } from 'model/plan';
import { PRICES } from '../../data/prices';

export function getPlanAmount(
  plan: NewPlan,
  seats?: number,
  billingInterval?: string,
  currency: 'USD' | 'EUR' | 'INR' = 'USD',
) {
  if (plan === 'PERSONAL') {
    return PRICES[currency][billingInterval?.toUpperCase() || 'MONTHLY']
      .PERSONAL;
  }

  if (plan === 'PROFESSIONAL') {
    return PRICES[currency][billingInterval?.toUpperCase() || 'MONTHLY']
      .PROFESSIONAL;
  }

  if (plan === 'TEAM') {
    switch (seats) {
      case 100:
        return PRICES[currency][billingInterval?.toUpperCase() || 'MONTHLY']
          .TEAM_100;
      case 50:
        return PRICES[currency][billingInterval?.toUpperCase() || 'MONTHLY']
          .TEAM_50;
      case 25:
        return PRICES[currency][billingInterval?.toUpperCase() || 'MONTHLY']
          .TEAM_25;
      case 10:
        return PRICES[currency][billingInterval?.toUpperCase() || 'MONTHLY']
          .TEAM_10;
      case 5:
      default:
        return PRICES[currency][billingInterval?.toUpperCase() || 'MONTHLY']
          .TEAM_5;
    }
  }

  return PRICES.USD[billingInterval?.toUpperCase() || 'MONTHLY'].PERSONAL;
}
