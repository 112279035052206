/* eslint-disable import/no-webpack-loader-syntax */
import { useCallback, useState } from 'react';
import { useWorker } from 'utils/useWorker';
import { saveAs } from 'file-saver';
import dataToCsvUrl from 'worker-plugin/loader?name=dataToCsv!./dataToCsvWorker';
import { Props } from './types';
import { DataToCsvWorker } from './dataToCsvWorker';

export function useLogic<T>({
  transformer,
  data,
  getData,
  fileName,
  onDone,
}: Props<T>) {
  const [loading, setLoading] = useState(false);
  const { worker: dataToCsv } = useWorker<DataToCsvWorker>(
    dataToCsvUrl,
    'DataToCsvWorker',
  );

  const exportData = useCallback(async () => {
    setLoading(true);
    const input = !getData ? data : await getData();
    const transformedData = await transformer(input);
    const csv = await dataToCsv(transformedData);
    const file = new File([csv], `${fileName}.csv`, {
      type: 'application/csv',
    });
    setLoading(false);
    saveAs(file);
    if (onDone) {
      onDone(input);
    }
  }, [data, getData, fileName, transformer, dataToCsv]);

  return { exportData, loading };
}
