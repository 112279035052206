import React from 'react';
import {
  Container,
  Content,
  Cards,
  TitleText,
  Text,
  SignInButton,
  BackLink,
  StyledPricingCard,
} from './styles';
import { useConnect } from './connect';
import { capitalizeFirstLetter } from '../../../utils/stringUtils';
import QuotaConditionsModal from './QuotaConditionsModal';

const WrongPlanChoice = () => {
  const {
    me,
    isGmail,
    requestedPlan,
    canMonthly,
    onSwitchAccount,
    cardDataYear,
    cardDataMonth,
    onTooltipClick,
  } = useConnect();

  return (
    <Container>
      <Content>
        <TitleText>
          You are trying to purchase a{' '}
          {requestedPlan ? capitalizeFirstLetter(requestedPlan) : ''} plan with{' '}
          <u>{me?.email}</u>
        </TitleText>
        <Text>
          {isGmail ? (
            <>Gmail.com addresses are only eligible to the Personal plan:</>
          ) : (
            <>
              Google Workspace addresses are only eligible to the Professional
              plan:
            </>
          )}
        </Text>
        <Cards>
          <StyledPricingCard
            onTooltipClick={onTooltipClick}
            data={cardDataYear}
          />
          {canMonthly && (
            <StyledPricingCard
              onTooltipClick={onTooltipClick}
              data={cardDataMonth}
            />
          )}
        </Cards>
        <Text>
          {isGmail ? (
            <>
              Do you want a Professional or a Team plan for your Google
              Workspace account?
            </>
          ) : (
            <>Do you want a Personal plan for your Gmail account?</>
          )}
        </Text>
        <SignInButton onClick={onSwitchAccount}>
          Sign-in with another account
        </SignInButton>
        <BackLink
          href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/pricing`}
        >
          Back to YAMM pricing plans
        </BackLink>
      </Content>
      <QuotaConditionsModal />
    </Container>
  );
};

export default WrongPlanChoice;
