import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import useQueryParams from '../../../utils/useQueryParams';
import {
  PersonalPriceCardDataYear,
  ProfessionalPriceCardDataYear,
  PersonalPriceCardDataMonth,
  ProfessionalPriceCardDataMonth,
} from './data';
import { PriceCardProps } from '../../../components/PriceCard/types';
import { useAuth } from '../../../data/context/AuthContext';
import { useMe } from '../../../data/hooks/useMe';
import { isGmailAddress } from '../../../utils/userUtils';
import { canMonthlyForPlan } from '../../../utils/billingIntervalFeatureFlagUtils';
import { useQuotaConditionsModalTrigger } from './QuotaConditionsModal/useQuotaConditionsModalTrigger';

export const useConnect = () => {
  const { me } = useMe();
  const { replace } = useHistory();
  const params = useQueryParams();
  const errorType = params.get('error');
  const requestedPlan = params.get('plan');
  const requestedSeats = params.get('seats');

  const { onSwitchAccount } = useAuth();

  const isGmail = me?.email ? isGmailAddress(me.email) : false;

  const canMonthly: boolean = useMemo(() => {
    switch (errorType) {
      case 'plan-reserved-for-individual-accounts':
        return canMonthlyForPlan('PROFESSIONAL');
      case 'plan-reserved-for-workspace-accounts':
      default:
        return canMonthlyForPlan('PERSONAL');
    }
  }, [errorType]);

  const cardDataYear: PriceCardProps = useMemo(() => {
    switch (errorType) {
      case 'plan-reserved-for-individual-accounts':
        return ProfessionalPriceCardDataYear;
      case 'plan-reserved-for-workspace-accounts':
      default:
        return PersonalPriceCardDataYear;
    }
  }, [errorType]);
  const cardDataMonth: PriceCardProps = useMemo(() => {
    switch (errorType) {
      case 'plan-reserved-for-individual-accounts':
        return ProfessionalPriceCardDataMonth;
      case 'plan-reserved-for-workspace-accounts':
      default:
        return PersonalPriceCardDataMonth;
    }
  }, [errorType]);

  useEffect(() => {
    const currentLocation = new URL(window.location.href);
    let cont = `/settings/billing?plan=${requestedPlan}`;
    if (requestedSeats) {
      cont += `&seats=${requestedSeats}`;
    }

    currentLocation.searchParams.set('continue', encodeURIComponent(cont));
    replace(currentLocation.pathname + currentLocation.search);
  }, []);

  const { trigger } = useQuotaConditionsModalTrigger();

  const onTooltipClick = useCallback(() => {
    if (errorType === 'plan-reserved-for-individual-accounts') {
      trigger();
    } else if (errorType === 'plan-reserved-for-workspace-accounts') {
      window.open(
        'https://support.yet-another-mail-merge.com/hc/en-us/articles/210730829#Paid-version',
      );
    }
  }, [errorType]);

  return {
    errorType,
    cardDataYear,
    cardDataMonth,
    me,
    isGmail,
    requestedPlan,
    canMonthly,
    onSwitchAccount,
    onTooltipClick,
  };
};
