import { User } from 'model/user';
import { useEffect } from 'react';
import { ClarityUtils } from '../../utils/clarityUtils';

export const useClarity = () => {
  useEffect(() => {
    ClarityUtils.setup();
  }, []);
};

export const useClarityIdentify = (me: User | undefined) => {
  useEffect(() => {
    if (me && me.email) {
      ClarityUtils.identify(me.email);
    }
  }, [me]);
};
