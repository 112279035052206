import { useStripe } from '@stripe/react-stripe-js';
import { useRef } from 'react';
import { Stripe } from '@stripe/stripe-js';

export const useStripeRef = () => {
  const stripe = useStripe();
  const stripeRef = useRef<Stripe | null>(null);
  stripeRef.current = stripe;

  return {
    stripeRef,
  };
};
