import { NewPlan } from 'model/plan';
import { PRICES } from '../../../../data/prices';

type AvailablePlan = {
  plan: NewPlan;
  seats: number;
  amount: number;
  billingInterval: string;
  currency: 'EUR' | 'USD' | 'INR';
};

export const getPlanId = (plan: string) =>
  process.env[`REACT_APP_${plan}`] || '';

export const availablePlans: { [key: string]: AvailablePlan } = {
  [getPlanId('PERSONAL_USD_YEARLY')]: {
    plan: 'PERSONAL',
    seats: 1,
    amount: PRICES.USD.YEARLY.PERSONAL,
    currency: 'USD',
    billingInterval: 'YEARLY',
  },
  [getPlanId('PERSONAL_EUR_YEARLY')]: {
    plan: 'PERSONAL',
    seats: 1,
    amount: PRICES.EUR.YEARLY.PERSONAL,
    currency: 'EUR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('PERSONAL_INR_YEARLY')]: {
    plan: 'PERSONAL',
    seats: 1,
    amount: PRICES.INR.YEARLY.PERSONAL,
    currency: 'INR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('PROFESSIONAL_USD_YEARLY')]: {
    plan: 'PROFESSIONAL',
    seats: 1,
    amount: PRICES.USD.YEARLY.PROFESSIONAL,
    currency: 'USD',
    billingInterval: 'YEARLY',
  },
  [getPlanId('PROFESSIONAL_EUR_YEARLY')]: {
    plan: 'PROFESSIONAL',
    seats: 1,
    amount: PRICES.EUR.YEARLY.PROFESSIONAL,
    currency: 'EUR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('PROFESSIONAL_INR_YEARLY')]: {
    plan: 'PROFESSIONAL',
    seats: 1,
    amount: PRICES.INR.YEARLY.PROFESSIONAL,
    currency: 'INR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_USD_5_YEARLY')]: {
    plan: 'TEAM',
    seats: 5,
    amount: PRICES.USD.YEARLY.TEAM_5,
    currency: 'USD',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_EUR_5_YEARLY')]: {
    plan: 'TEAM',
    seats: 5,
    amount: PRICES.EUR.YEARLY.TEAM_5,
    currency: 'EUR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_INR_5_YEARLY')]: {
    plan: 'TEAM',
    seats: 5,
    amount: PRICES.INR.YEARLY.TEAM_5,
    currency: 'INR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_USD_10_YEARLY')]: {
    plan: 'TEAM',
    seats: 10,
    amount: PRICES.USD.YEARLY.TEAM_10,
    currency: 'USD',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_EUR_10_YEARLY')]: {
    plan: 'TEAM',
    seats: 10,
    amount: PRICES.EUR.YEARLY.TEAM_10,
    currency: 'EUR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_INR_10_YEARLY')]: {
    plan: 'TEAM',
    seats: 10,
    amount: PRICES.INR.YEARLY.TEAM_10,
    currency: 'INR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_USD_25_YEARLY')]: {
    plan: 'TEAM',
    seats: 25,
    amount: PRICES.USD.YEARLY.TEAM_25,
    currency: 'USD',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_EUR_25_YEARLY')]: {
    plan: 'TEAM',
    seats: 25,
    amount: PRICES.EUR.YEARLY.TEAM_25,
    currency: 'EUR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_INR_25_YEARLY')]: {
    plan: 'TEAM',
    seats: 25,
    amount: PRICES.INR.YEARLY.TEAM_25,
    currency: 'INR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_USD_50_YEARLY')]: {
    plan: 'TEAM',
    seats: 50,
    amount: PRICES.USD.YEARLY.TEAM_50,
    currency: 'USD',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_EUR_50_YEARLY')]: {
    plan: 'TEAM',
    seats: 50,
    amount: PRICES.EUR.YEARLY.TEAM_50,
    currency: 'EUR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_INR_50_YEARLY')]: {
    plan: 'TEAM',
    seats: 50,
    amount: PRICES.INR.YEARLY.TEAM_50,
    currency: 'INR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_USD_100_YEARLY')]: {
    plan: 'TEAM',
    seats: 100,
    amount: PRICES.USD.YEARLY.TEAM_100,
    currency: 'USD',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_EUR_100_YEARLY')]: {
    plan: 'TEAM',
    seats: 100,
    amount: PRICES.EUR.YEARLY.TEAM_100,
    currency: 'EUR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('TEAM_INR_100_YEARLY')]: {
    plan: 'TEAM',
    seats: 100,
    amount: PRICES.INR.YEARLY.TEAM_100,
    currency: 'INR',
    billingInterval: 'YEARLY',
  },
  [getPlanId('PERSONAL_USD_MONTHLY')]: {
    plan: 'PERSONAL',
    seats: 1,
    amount: PRICES.USD.MONTHLY.PERSONAL,
    currency: 'USD',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('PERSONAL_EUR_MONTHLY')]: {
    plan: 'PERSONAL',
    seats: 1,
    amount: PRICES.EUR.MONTHLY.PERSONAL,
    currency: 'EUR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('PERSONAL_INR_MONTHLY')]: {
    plan: 'PERSONAL',
    seats: 1,
    amount: PRICES.INR.MONTHLY.PERSONAL,
    currency: 'INR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('PROFESSIONAL_USD_MONTHLY')]: {
    plan: 'PROFESSIONAL',
    seats: 1,
    amount: PRICES.USD.MONTHLY.PROFESSIONAL,
    currency: 'USD',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('PROFESSIONAL_EUR_MONTHLY')]: {
    plan: 'PROFESSIONAL',
    seats: 1,
    amount: PRICES.EUR.MONTHLY.PROFESSIONAL,
    currency: 'EUR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('PROFESSIONAL_INR_MONTHLY')]: {
    plan: 'PROFESSIONAL',
    seats: 1,
    amount: PRICES.INR.MONTHLY.PROFESSIONAL,
    currency: 'INR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_USD_5_MONTHLY')]: {
    plan: 'TEAM',
    seats: 5,
    amount: PRICES.USD.MONTHLY.TEAM_5,
    currency: 'USD',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_EUR_5_MONTHLY')]: {
    plan: 'TEAM',
    seats: 5,
    amount: PRICES.EUR.MONTHLY.TEAM_5,
    currency: 'EUR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_INR_5_MONTHLY')]: {
    plan: 'TEAM',
    seats: 5,
    amount: PRICES.INR.MONTHLY.TEAM_5,
    currency: 'INR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_USD_10_MONTHLY')]: {
    plan: 'TEAM',
    seats: 10,
    amount: PRICES.USD.MONTHLY.TEAM_10,
    currency: 'USD',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_EUR_10_MONTHLY')]: {
    plan: 'TEAM',
    seats: 10,
    amount: PRICES.EUR.MONTHLY.TEAM_10,
    currency: 'EUR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_INR_10_MONTHLY')]: {
    plan: 'TEAM',
    seats: 10,
    amount: PRICES.INR.MONTHLY.TEAM_10,
    currency: 'INR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_USD_25_MONTHLY')]: {
    plan: 'TEAM',
    seats: 25,
    amount: PRICES.USD.MONTHLY.TEAM_25,
    currency: 'USD',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_EUR_25_MONTHLY')]: {
    plan: 'TEAM',
    seats: 25,
    amount: PRICES.EUR.MONTHLY.TEAM_25,
    currency: 'EUR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_INR_25_MONTHLY')]: {
    plan: 'TEAM',
    seats: 25,
    amount: PRICES.INR.MONTHLY.TEAM_25,
    currency: 'INR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_USD_50_MONTHLY')]: {
    plan: 'TEAM',
    seats: 50,
    amount: PRICES.USD.MONTHLY.TEAM_50,
    currency: 'USD',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_EUR_50_MONTHLY')]: {
    plan: 'TEAM',
    seats: 50,
    amount: PRICES.EUR.MONTHLY.TEAM_50,
    currency: 'EUR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_INR_50_MONTHLY')]: {
    plan: 'TEAM',
    seats: 50,
    amount: PRICES.INR.MONTHLY.TEAM_50,
    currency: 'INR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_USD_100_MONTHLY')]: {
    plan: 'TEAM',
    seats: 100,
    amount: PRICES.USD.MONTHLY.TEAM_100,
    currency: 'USD',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_EUR_100_MONTHLY')]: {
    plan: 'TEAM',
    seats: 100,
    amount: PRICES.EUR.MONTHLY.TEAM_100,
    currency: 'EUR',
    billingInterval: 'MONTHLY',
  },
  [getPlanId('TEAM_INR_100_MONTHLY')]: {
    plan: 'TEAM',
    seats: 100,
    amount: PRICES.INR.MONTHLY.TEAM_100,
    currency: 'INR',
    billingInterval: 'MONTHLY',
  },
  unlimited: {
    plan: 'UNLIMITED',
    seats: Infinity,
    amount: Infinity,
    currency: 'EUR',
    billingInterval: 'YEARLY',
  },
};
