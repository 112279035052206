import { getPlanAmount } from 'utils/getPlanAmount';
import { getPlanQuota } from 'utils/getPlanQuota';
import { Props as LogicProps } from './types';

export const useLogic = ({
  plan,
  seats,
  currency,
  billingInterval,
  canMonthly,
  freeUserQuota,
}: LogicProps & { currency: 'EUR' | 'USD' | 'INR' }) => {
  const quota = getPlanQuota(plan, freeUserQuota);
  const amount = canMonthly
    ? getPlanAmount(plan, seats, billingInterval, currency)
    : getPlanAmount(plan, seats, 'YEARLY', currency);
  return { amount, quota };
};
