import { client, clientV2 } from 'data/client';
import { Input as GetUserResponse, UserRolloutInput } from 'model/user';

export async function getMe() {
  const { data } = await client.get<GetUserResponse>('/users/me');
  return data;
}

export async function getMeRollout() {
  const { data } = await clientV2.get<UserRolloutInput>(
    `/users/workspace-activation-status/me`,
    {
      params: {
        checkIsRegistered: true,
        throw404: false,
        includeUserV1: false,
        includeUserV2: false,
        includeUserV2Workspace: false,
        includeFBData: false,
      },
    },
  );

  return data;
}
