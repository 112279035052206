import React, { createContext, useContext, ReactNode } from 'react';

const initialUrl = window.location.href;
const InitialUrlContext = createContext<string>(initialUrl);

export const useInitialUrl = () => useContext<string>(InitialUrlContext);

export const InitialUrlProvider = ({ children }: { children: ReactNode }) => {
  return (
    <InitialUrlContext.Provider value={initialUrl}>
      {children}
    </InitialUrlContext.Provider>
  );
};
