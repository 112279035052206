import keysOf from 'utils/keysOf';
import { percentage } from 'utils/percentage';

export type Input = {
  id: string;
  value: 'Yes' | 'No';
};

export type PollResult = {
  info: string;
  count: number;
};

export const normalizePoll = (input: Input[]) => {
  const groupedResults = input.reduce((acc, item) => {
    if (acc[item.value]) {
      acc[item.value].count += 1;
    } else {
      acc[item.value] = { count: 1, percentage: 0 };
    }
    return acc;
  }, {} as { [key: string]: { count: number; percentage: number } });

  const totalResults = input.length;

  keysOf(groupedResults).forEach((reply) => {
    groupedResults[reply].percentage = percentage(
      groupedResults[reply].count,
      totalResults,
    );
  });

  return groupedResults;
};

export const normalizePollResults = (input: PollResult[]) => {
  let totalResults: number = 0;
  const groupedResults: {
    [p: string]: { count: number; percentage: number };
  } = {};

  input.forEach((pr) => {
    totalResults += pr.count;
  });

  input.forEach((pr) => {
    groupedResults[pr.info] = {
      count: pr.count,
      percentage: percentage(pr.count, totalResults),
    };
  });

  return groupedResults;
};

export type Poll = ReturnType<typeof normalizePoll>;
