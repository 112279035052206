// todo add tests
import { TransactionInput, TransactionLog } from './types';
import { pushEcommerceData } from '../dataLayer';

const TRANSACTION_DATA_KEY: string = '__transactionData';
const TRANSACTION_STATUS_KEY: string = '__transactionStatus';

const STATUS_SUCCESS: string = 'success';

const getTransactionKey = (sessionId: string) =>
  `${TRANSACTION_DATA_KEY}-${sessionId}`;

const getTransactionStatusKey = (sessionId: string) =>
  `${TRANSACTION_STATUS_KEY}-${sessionId}`;

const isCurrentSessionRegistered = (sessionId: string): boolean =>
  sessionStorage.getItem(getTransactionStatusKey(sessionId)) === STATUS_SUCCESS;

const setCurrentSessionStatus = (sessionId: string, sessionStatus: string) =>
  sessionStorage.setItem(getTransactionStatusKey(sessionId), sessionStatus);

const getSku = (plan: string, seats?: number): string => {
  if (plan === 'PERSONAL') {
    return 'YAMM-PERS';
  }

  if (plan === 'PROFESSIONAL') {
    return 'YAMM-IND';
  }

  if (plan === 'TEAM') {
    return `YAMM-TEAM${seats}`;
  }

  return 'YAMM-UNKNOWN-PLAN';
};

const getTransactionObject = (data: TransactionInput): TransactionLog => {
  let taxValue = 0;
  if (data.tax) {
    taxValue = data.basePrice * (data.tax / 100);
  }

  return {
    transactionId: data.sessionId,
    transactionAffiliation: 'yamm.com',
    transactionTotal: data.basePrice + taxValue,
    transactionTax: taxValue,
    transactionProducts: [
      {
        name: 'YAMM',
        price: data.basePrice,
        quantity: 1,
        sku: getSku(data.plan, data.seats),
      },
    ],
  };
};

export const registerTransactionSuccess = (checkoutSessionId: string) => {
  try {
    if (!isCurrentSessionRegistered(checkoutSessionId)) {
      const storedString: string | null = sessionStorage.getItem(
        getTransactionKey(checkoutSessionId),
      );

      if (storedString != null) {
        const data: any = JSON.parse(storedString);
        pushEcommerceData(data);

        setCurrentSessionStatus(checkoutSessionId, STATUS_SUCCESS);
      }
    }
  } catch (e) {
    // do nothing
  }
};

export const storeTransactionData = (data: TransactionInput) => {
  try {
    // store transaction object
    sessionStorage.setItem(
      getTransactionKey(data.sessionId),
      JSON.stringify(getTransactionObject(data)),
    );
  } catch (e) {
    // do nothing
  }
};
