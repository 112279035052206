import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import GoogleButton from 'components/GoogleButton';

import { useAuth } from 'data/context/AuthContext';
import testingVar from 'utils/testingVar';

import TrustpilotScore from 'assets/images/icons/trustpilot-score.png';
import TosAgreementText from '../../TosAgreementText';

import { stepOneLoad, stepOneSignUp } from '../events';

import {
  Card,
  Title,
  ImageWrapper,
  YammImage,
  LinkWrapper,
} from '../../styles';
import { Text } from '../styles';
import { pushFunnel } from '../../../../utils/dataLayer';

const StepOne = () => {
  const { onSignUp, clientId } = useAuth();
  const history = useHistory();

  useEffect(() => {
    stepOneLoad();
    history.replace('signup');
    pushFunnel('Create your account', '1');
  }, []);

  return (
    <>
      <Card>
        <ImageWrapper>
          <YammImage />
        </ImageWrapper>
        <Title>Welcome to YAMM</Title>
        {clientId ? (
          <GoogleButton
            variant="SIGN_UP"
            data-test-id={testingVar('submit-login')}
            onClick={() => {
              stepOneSignUp();
              onSignUp();
            }}
          />
        ) : null}
        <TosAgreementText />
      </Card>
      <Text>
        Already have a YAMM account? <Link to="/login">Sign in</Link>
      </Text>
      <LinkWrapper href="https://www.trustpilot.com/review/yamm.com">
        <img alt="trustpilot-score" src={TrustpilotScore} />
      </LinkWrapper>
    </>
  );
};

export default StepOne;
