import React, { memo } from 'react';
import { Props } from './types';
import { Container, DownloadIcon, Loader } from './styles';
import { useLogic } from './logic';

function ExportToCsv<T>({
  data,
  getData,
  transformer,
  fileName,
  onDone,
  ...props
}: Props<T>) {
  const { exportData, loading } = useLogic({
    data,
    transformer,
    fileName,
    onDone,
  });

  return (
    <Container
      {...props}
      onClick={exportData}
      disabled={!data.length}
      data-test-id="submit-export"
    >
      {loading ? (
        <>
          <Loader />
          <span>Exporting...</span>
        </>
      ) : (
        <>
          <DownloadIcon />
          <span>Export to CSV</span>
        </>
      )}
    </Container>
  );
}

export default memo(ExportToCsv);
