import { captureException } from '@sentry/react';
import { logToStackDriver } from 'utils/logToStackdriver';
import { User } from '../../../model/user';
import {
  getDomainFromEmail,
  getUserIdFromEmail,
} from '../../../utils/userUtils';

export const logErrorLoginRequest = (
  error?: string,
  error_description?: string,
) => {
  try {
    logToStackDriver({
      timestamp: Date.now(),
      message: 'Webapp login failed',
      event_type: 'webapp_login_request',
      error,
      error_description,
    });
  } catch (err) {
    captureException(`${error} / ${error_description}`);
    captureException(err);
  }
};

export const logSuccessLoginRequest = (code: string) => {
  try {
    logToStackDriver({
      code,
      timestamp: Date.now(),
      message: 'Webapp login success',
      event_type: 'webapp_login_request',
    });
  } catch (err) {
    captureException(err);
  }
};

export const logLoginSuccess = (user: User) => {
  logToStackDriver({
    event_type: 'webapp_login_request_successful',
    user_id: getUserIdFromEmail(user.email),
    user_email: user.email,
    domain: getDomainFromEmail(user.email),
    plan: user.plan,
    timestamp: Date.now(),
    ui_type: 'legacy',
  });
};
