import React from 'react';

import Container from 'components/Container';
import GoogleButton from 'components/GoogleButton';
import TrustpilotScore from 'assets/images/icons/trustpilot-score-white.png';
import GoogleReviews from 'assets/images/icons/google-reviews.png';
import testingVar from 'utils/testingVar';
import {
  Main,
  Nav,
  NavLink,
  Card,
  Title,
  ImageWrapper,
  YammImage,
  LinkWrapper,
} from '../styles';
import {
  Aside,
  AsideBackdrop,
  Footer,
  LoginContainer,
  Subtitle,
  ErrorWrapper,
  AlertIcon,
  Loader,
  ErrorContainer,
  Badges,
  QuoteBlock,
  Quote,
  QuotationIcon,
} from './styles';
import { useConnect } from './connect';
import TosAgreementText from '../TosAgreementText';

const Login = () => {
  const {
    clientId,
    onLogin,
    onSignUp,
    onSwitchAccount,
    isLoading,
    error,
    loginTestingVar,
  } = useConnect();
  return (
    <LoginContainer>
      <Main>
        <Container>
          <Card>
            <ImageWrapper>
              <YammImage />
            </ImageWrapper>
            {error && error.type !== 'no-user' && (
              <ErrorWrapper>
                <ErrorContainer>
                  <AlertIcon />
                  {error.type === 'session-expired' ? (
                    <span data-test-id={testingVar('session-expired')}>
                      Your session has expired, please sign in again
                    </span>
                  ) : null}
                  {error.type === 'google' ? (
                    <span>
                      There was an error using Google&apos;s sign in method
                    </span>
                  ) : null}
                  {error.type === 'can-not-create-user' ? (
                    <span>There was an error creating your account</span>
                  ) : null}
                </ErrorContainer>
              </ErrorWrapper>
            )}
            {error && error.type === 'no-user' ? (
              <>
                <ErrorWrapper>
                  <ErrorContainer>
                    <AlertIcon />
                    <span data-test-id={testingVar('no-user')}>
                      <b>{error.payload}</b> doesn’t have a YAMM account yet.
                      <br />
                      Click below to create a new account:
                    </span>
                  </ErrorContainer>
                </ErrorWrapper>
                <GoogleButton
                  variant="SIGN_UP"
                  data-test-id={testingVar('submit-login')}
                  onClick={onSignUp}
                />
                <TosAgreementText />
              </>
            ) : (
              <>
                {clientId && !isLoading ? (
                  <>
                    <Title>Welcome back!</Title>
                    <GoogleButton
                      data-test-id={testingVar('submit-login')}
                      onClick={onLogin}
                    />
                    {loginTestingVar ? (
                      <span data-test-id={loginTestingVar} />
                    ) : null}
                  </>
                ) : null}
                {isLoading ? <Loader size={36} /> : null}
              </>
            )}
          </Card>

          <Card>
            {clientId && !isLoading ? (
              <>
                {error && error.type === 'no-user' ? (
                  <>
                    <Subtitle>Did you want to use another account?</Subtitle>
                    <GoogleButton
                      variant="SIGN_IN_ANOTHER_ACCOUNT"
                      data-test-id={testingVar('submit-signup')}
                      onClick={onSwitchAccount}
                    />
                  </>
                ) : (
                  <>
                    <Subtitle>Don&apos;t have a YAMM account yet?</Subtitle>
                    <GoogleButton
                      variant="SIGN_UP"
                      data-test-id={testingVar('submit-login')}
                      onClick={onSignUp}
                    />
                    <TosAgreementText />
                  </>
                )}
              </>
            ) : null}
            {isLoading ? <Loader size={36} /> : null}
          </Card>
        </Container>
      </Main>
      <Footer>
        <Container>
          <Nav>
            <NavLink href="https://support.yet-another-mail-merge.com/hc/en-us">
              Help center
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/terms-of-service/`}
            >
              Terms of service
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/privacy-policy/`}
            >
              Privacy policy
            </NavLink>
            <NavLink
              href={`${process.env.REACT_APP_MARKETING_WEBSITE_URL}/cookie-policy/`}
            >
              Cookie policy
            </NavLink>
            <NavLink href="https://talarian.io/data-processing-agreement">
              Data processing agreement
            </NavLink>
          </Nav>
        </Container>
      </Footer>
      <Aside>
        <AsideBackdrop />

        <QuoteBlock>
          <Quote className="right">
            <QuotationIcon />
            YAMM is easy to use and has greater success with delivering emails
            to inboxes instead of spam folders compared with other email
            services.
          </Quote>
          <Quote className="left">
            <QuotationIcon />
            This add-on has saved me hours of time, many dollars of postage, and
            several trees-worth of paper. It&apos;s easy to get set up. I&apos;m
            so thankful!
          </Quote>
          <Quote className="right">
            <QuotationIcon />
            It has been a game changer for our business. Being able to send mass
            emails and you can track them. We love it!
          </Quote>
        </QuoteBlock>

        <Badges>
          <LinkWrapper href="https://workspace.google.com/marketplace/app/yet_another_mail_merge_mail_merge_for_gm/52669349336">
            <img alt="google-reviews" src={GoogleReviews} />
          </LinkWrapper>
          <LinkWrapper href="https://www.trustpilot.com/review/yamm.com">
            <img alt="trustpilot-score" src={TrustpilotScore} />
          </LinkWrapper>
        </Badges>
      </Aside>
    </LoginContainer>
  );
};

export default Login;
