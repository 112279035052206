import React, { memo } from 'react';
import Component from 'components/RadioInput';
// import useConnect from './connect';
import { Container, Content, InputContainer, Label } from './styles';
import { Props } from './types';

const RadioInput = ({ billingInterval, onChange }: Props) => {
  return (
    <Container>
      <Content>
        <InputContainer>
          <Component
            checked={billingInterval === 'MONTHLY'}
            id="radio-billing-month"
            onClick={() => onChange('MONTHLY')}
          />
          <Label htmlFor="radio-billing-month">Billed monthly</Label>
        </InputContainer>
        <InputContainer>
          <Component
            checked={billingInterval === 'YEARLY'}
            id="radio-billing-year"
            onClick={() => onChange('YEARLY')}
          />
          <Label htmlFor="radio-billing-year">
            Billed annually - save over 75%
          </Label>
        </InputContainer>
      </Content>
    </Container>
  );
};

export default memo(RadioInput);
