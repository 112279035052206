import { Plan } from './plan';
import { normalizeStat, Stat } from './stat';
import { normalizeUserLicense, UserLicense } from './userLicense';

export type UserType = 'BUSINESS' | 'STANDARD';

type UserInput = {
  email: string;
  fullName: string;
  picture: string;
  lastTimeUsed: string;
  lastTimeUsedTimestamp: string;
  installationTimestamp: string;
  userType: UserType;
  plan: Plan;
  stats: Stat;
  stripeCustomerId: string;
  freeUserQuota: number;
};

type CustomerInput = UserInput & {
  licence: UserLicense;
};

export type Input = UserInput | CustomerInput;

export type UserRolloutInput = {
  isRegistered: boolean;
  isEligible: boolean;
};

export const normalizeUser = (input: Input) => {
  const user = {
    id: input.email,
    email: input.email,
    initials: input.fullName
      ?.split(' ')
      .map((word) => word[0].toUpperCase())
      .slice(0, 2)
      .join(''),
    name: input.fullName,
    photo: input.picture,
    userType: input.userType,
    plan: input.plan,
    lastTimeUsed: parseInt(input.lastTimeUsedTimestamp, 10),
    installationTimestamp: input.installationTimestamp
      ? parseInt(input.installationTimestamp, 10)
      : null,
    stats: normalizeStat(input.stats),
    stripeCustomerId: input.stripeCustomerId,
    freeUserQuota: input.freeUserQuota,
  };

  if (user.name == null) {
    user.name = user.email;
  }

  if ('licence' in input) {
    const customer = {
      license: normalizeUserLicense(input.licence),
      ...(input.stripeCustomerId !== undefined
        ? { stripeCustomerId: input.stripeCustomerId }
        : undefined),
    };

    return { ...user, ...customer } as const;
  }

  return { ...user } as const;
};

export type User = ReturnType<typeof normalizeUser>;
