import React from 'react';
import Main from 'containers/Main';
import Guest from 'containers/Guest';
import SignUp from 'containers/Guest/SignUp';
import { useAuth } from 'data/context/AuthContext';
import { Route, Switch } from 'react-router-dom';
import Unsubscription from 'containers/Common/Unsubscription';
import PollAnswer from 'containers/Common/PollAnswer';
import StopAutomaticRenewal from 'containers/Common/StopAutomaticRenewal';
import { useClarity } from 'data/hooks/useClarity';
import WrongPlanChoice from '../Common/WrongPlanChoice';

const Root = () => {
  const { token } = useAuth();
  useClarity();

  return (
    <Switch>
      <Route component={Unsubscription} path="/unsubscribed" />
      <Route component={PollAnswer} path="/formresponse" />
      <Route component={StopAutomaticRenewal} path="/stop-automatic-renewal" />
      <Route component={WrongPlanChoice} path="/wrong-plan" />
      <Route component={SignUp} path={['/signup', '/get-started']} />
      {token ? <Main /> : <Guest />}
    </Switch>
  );
};

export default Root;
