import { useHistory } from 'react-router-dom';
import useQueryParams from 'utils/useQueryParams';

export function useIntent() {
  const { replace } = useHistory();
  const query = useQueryParams();

  function goToIntent() {
    const intent = query.get('continue');
    if (intent === null) return;

    const decodeIntent = decodeURIComponent(intent);
    if (decodeIntent.length > 0 && decodeIntent !== '/') {
      replace(decodeIntent);
    }
  }

  return { goToIntent };
}
