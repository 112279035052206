import React, { ChangeEvent, memo, useCallback } from 'react';
import { getPlanAmount } from 'utils/getPlanAmount';
import { getFormattedValue } from '../logic';
import { Component, Container, Icon } from './styles';
import { Props } from './types';

const SelectBillingInterval = ({
  onChange,
  billingInterval,
  showAmount,
  plan,
  seats,
  currency,
}: Props) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { value: nextValue } = event.currentTarget;
      if (nextValue) onChange(nextValue);
    },
    [onChange],
  );

  return (
    <Container>
      <Component
        name="totalUsers"
        onChange={handleChange}
        value={billingInterval}
        data-test-id={`billing-interval-selector-${plan.toLowerCase()}`}
      >
        <option value="MONTHLY">
          {showAmount
            ? `${getFormattedValue(
                getPlanAmount(plan, seats, 'MONTHLY'),
                currency,
              )} / month`
            : ''}
        </option>
        <option value="YEARLY">
          {showAmount
            ? `${getFormattedValue(
                getPlanAmount(plan, seats, 'YEARLY'),
                currency,
              )} / year`
            : ''}
        </option>
      </Component>
      <Icon />
    </Container>
  );
};

export default memo(SelectBillingInterval);
