import { useMailMerges } from 'data/hooks/useMailMerges';
import { useMe } from 'data/hooks/useMe';
import { useScheduledMailMerges } from 'data/hooks/useScheduledMailMerges';
import { useTrackingData } from 'data/hooks/useTrackingData.';
import { enrichCampaigns } from 'model/tracking';
import { useRouteMatch } from 'react-router-dom';

export const useConnect = () => {
  const { url, path } = useRouteMatch();
  const { me } = useMe();
  const userId = me?.email;
  const stats = me?.stats;
  const { mailMerges, getMailMerges, status } = useMailMerges(userId);
  const { setPage, trackingData } = useTrackingData(userId);
  const {
    scheduledMailMerges,
    status: scheduledMergesStatus,
  } = useScheduledMailMerges(userId);

  const isLoading = status === 'loading' || !userId;

  return {
    url,
    path,
    campaigns: enrichCampaigns(mailMerges, trackingData),
    getCampaigns: getMailMerges,
    setPage,
    isLoading,
    scheduledMailMerges,
    scheduledMergesStatus,
    stats,
  };
};
