import React, { FC, memo } from 'react';

import { ReactComponent as MailIcon } from 'assets/images/icons/scheduled-mail.svg';
import { ReactComponent as SentThisMonthIcon } from 'assets/images/icons/sent-this-month.svg';
import { ReactComponent as ArrowIcon } from 'assets/images/icons/arrow-circle.svg';
import { ReactComponent as TeamIcon } from 'assets/images/icons/team.svg';
import { IconProps, Props } from './types';
import {
  StatsCardContainer,
  IconWrapper,
  Stat,
  Number,
  NumberPlaceholder,
  Text,
} from './styles';

const Icon: FC<IconProps> = ({ variant }) => {
  switch (variant) {
    case 'team':
      return <TeamIcon />;
    case 'merges':
      return <MailIcon />;
    case 'sent-this-month':
      return <SentThisMonthIcon />;
    case 'emails':
    default:
      return <ArrowIcon />;
  }
};

const StatsCard: FC<Props> = ({ number, text, content, variant, ...props }) => {
  return (
    <StatsCardContainer $variant={variant} {...props}>
      <IconWrapper>
        <Icon variant={variant} />
      </IconWrapper>
      <Stat>
        {number !== undefined ? (
          <Number>{number}</Number>
        ) : (
          <NumberPlaceholder />
        )}
        {text && <Text>{text}</Text>}
        {content && content}
      </Stat>
    </StatsCardContainer>
  );
};

export default memo(StatsCard);
