import { useQuery } from 'react-query';
import { useMemo, useState } from 'react';
import { Page } from 'components/DesktopTable/types';
import { getMailMergesTrackingData } from 'data/requests/tracking';

export const useTrackingData = (userId?: string) => {
  const [page, setPage] = useState<Page>();
  const sheetKeys = useMemo(
    () =>
      !page?.length
        ? undefined
        : page.map(({ spreadsheetId, sheetId }) => ({
            spreadsheetId,
            sheetId,
          })),
    [{ page }],
  );
  const { data: trackingData } = useQuery(
    ['mail-merges-tracking', userId, sheetKeys],
    getMailMergesTrackingData,
    {
      enabled: !!userId && !!sheetKeys,
    },
  );

  return {
    setPage,
    trackingData,
  };
};
