import React, {
  createContext,
  DispatchWithoutAction,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  UseGoogleLoginOptionsAuthCodeFlow,
  useGoogleLogin,
} from '@react-oauth/google';
import { AxiosError } from 'axios';
import { QueryStatus, useQuery } from 'react-query';
import { queryCache } from 'data/cache';
import { useIntent } from 'utils/useIntent';
import { useMe } from 'data/hooks/useMe';
import { useHistory } from 'react-router-dom';
import { captureException, setUser } from '@sentry/minimal';
import LoadingScreen from 'components/LoadingScreen';
import { AnimatePresence } from 'framer-motion';
import { useCreateUser } from 'data/hooks/useUsers';
import IncognitoMode from 'containers/Common/IncognitoMode';
import { AuthContextProps, ErrorType } from './types';
import { useSessionExpiration } from './useSessionExpiration';
import { useSessionRefresher } from './useSessionRefresher';
import { Token, TokenQuery } from './token';
import { AuthCookie } from '../../../utils/authCookies';
import { Google } from './google-auth';
import { pushSignUp } from '../../../utils/dataLayer';
import {
  logErrorLoginRequest,
  logLoginSuccess,
  logSuccessLoginRequest,
} from './logger';
import { useDelegateLogin } from './useDelegateLogin';

const COMPOSE_SCOPE = 'https://www.googleapis.com/auth/gmail.compose';

const AuthContext = createContext<AuthContextProps>({
  token: undefined,
  clientId: '',
  onLogin: () => {},
  onSignUp: () => {},
  askForComposePermission: async () => {},
  composePermissionGranted: async () => false,
  logout: () => {},
  onSwitchAccount: () => {},
  getUserStatus: QueryStatus.Idle,
  loading: false,
  refreshing: false,
  error: null,
});

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const { push } = useHistory();
  const { goToIntent } = useIntent();
  const [error, setError] = useState<ErrorType | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [refreshing, setRefreshing] = useState<boolean>(true);
  const [tokensLoaded, setTokensLoaded] = useState<boolean>(false);
  const clientId = useRef(process.env.REACT_APP_GOOGLE_CLIENT_ID).current;

  const {
    expirationWorker,
    triggerExpiringSessionTimeout,
  } = useSessionExpiration();

  const {
    refreshWorker,
    triggerRefreshSessionTimeout,
    clearRefreshValue,
  } = useSessionRefresher();

  const { data } = useQuery<TokenQuery>('token', async () => Token.get(), {
    cacheTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { createUser, status: createUserStatus } = useCreateUser();

  const {
    loginEnabled,
    delegateLoading,
    checkDelegate,
    delegateRegister,
  } = useDelegateLogin(tokensLoaded, createUser, setRefreshing, setError);

  const { status: getUserStatus, refetch: getUser } = useMe({
    enabled: tokensLoaded && loginEnabled,
    refetchOnWindowFocus: !!data?.token,
    retryDelay: () => 500,
    retry: (failureCount, e) => {
      if (failureCount > 0) {
        Token.clear();
        return false;
      }
      if ((e as AxiosError)?.response?.status === 401) {
        Google.refreshAuth().catch(() => {
          Token.clear();
        });
        return true;
      }
      if ((e as AxiosError)?.response?.status === 404) {
        setError({
          type: 'no-user',
          payload: Google.getUserData()?.email ?? '',
        });
        Token.clear();
        return false;
      }

      if (e != null) {
        captureException(e as AxiosError);
      }
      return true;
    },
    onSettled: () => {
      setRefreshing(false);
    },
  });

  const logout = useCallback(async () => {
    try {
      await refreshWorker.clearRefreshInterval();
      await expirationWorker.clearExpirationTimeout();
      await Google.signOut();
      Token.clear();
      queryCache.clear();
      AuthCookie.remove();
      setTokensLoaded(false);
      setUser(null);
      push('/login');
    } catch (e) {
      captureException(e);
    }
  }, [refreshWorker, expirationWorker, push]);

  useEffect(() => {
    if (!data || !data.expiresAt) return;
    triggerRefreshSessionTimeout(data);
  }, [data, triggerRefreshSessionTimeout]);

  useEffect(() => {
    try {
      const { token } = Token.get();
      if (token) {
        setTokensLoaded(true);
      } else {
        Google.refreshAuth().then(async (authRefreshed) => {
          if (authRefreshed) {
            queryCache.clear();
            const { token: tokenAfterRefresh, expiresAt } = Token.get();
            const profile = await Google.getAndSetUserInfo(
              tokenAfterRefresh as string,
            );
            if (profile) {
              setUser({
                email: profile.email,
              });
              if (!(await delegateRegister())) return;
              Google.saveProfileData(profile);
            }
            AuthCookie.set();
            setTokensLoaded(true);
            triggerExpiringSessionTimeout({
              expiresAt,
              logout,
              setError,
            });
          } else {
            Token.clear();
            AuthCookie.remove();
            setRefreshing(false);
          }
        });
      }
    } catch (err: any) {
      if (err.message === 'cookie-error') {
        setError({ type: 'incognito-mode' });
      } else {
        throw err;
      }
    }
  }, [setError]);

  const loginFlow = async (params: {
    code: string;
    switchAccount?: boolean;
    createAccount?: boolean;
    newPermission?: boolean;
  }) => {
    logSuccessLoginRequest(params.code);
    if (params.switchAccount) {
      setRefreshing(true);
      Token.clear();
      queryCache.clear();
      await refreshWorker.clearRefreshInterval();
      await expirationWorker.clearExpirationTimeout();
    }
    setError(null);
    setLoading(true);
    const tokens = await Google.getTokens(params.code);
    if (!tokens) {
      setError({ type: 'google' });
      setLoading(false);
      return;
    }
    const extendedValue = tokens?.expiresAt * 1000;
    const expiresAt = Date.now() + extendedValue;
    const userInfo = await Google.getAndSetUserInfo(tokens.accessToken);
    if (!userInfo) {
      setError({ type: 'google' });
      setLoading(false);
      return;
    }
    setUser({
      email: userInfo.email,
    });
    Token.set(
      {
        token: tokens.accessToken,
        backendIdToken: tokens.backendIdToken,
        sessionId: tokens.sessionId,
        idToken: tokens.idToken,
        expiresAt,
      },
      true,
    );
    if (!params.newPermission && (await checkDelegate(params.createAccount))) {
      return;
    }
    let fetchUser = await getUser();
    if (!fetchUser && !params.createAccount) {
      Token.clear();
      AuthCookie.remove();
      setError({
        type: 'no-user',
        payload: userInfo.email,
      });
      setLoading(false);
    } else {
      if (!fetchUser) {
        try {
          Token.set(
            {
              token: tokens.accessToken,
              backendIdToken: tokens.backendIdToken,
              sessionId: tokens.sessionId,
              idToken: tokens.idToken,
              expiresAt,
            },
            true,
          );
          await createUser();
          fetchUser = await getUser();
          pushSignUp();
        } catch (e) {
          captureException(e);
          setError({ type: 'can-not-create-user' });
          setLoading(false);
          Token.clear();
          return;
        }
      }
      AuthCookie.set();
      Google.saveProfileData(userInfo);
      setLoading(false);
      setError(null);
      setRefreshing(false);
      clearRefreshValue();
      triggerExpiringSessionTimeout({
        expiresAt,
        logout,
        setError,
      });

      if (fetchUser) {
        logLoginSuccess(fetchUser);
      }
    }
  };

  const onLoginSuccess = async (codeResponse: { code: string }) => {
    await loginFlow({
      code: codeResponse.code,
    });
  };
  const onPermissionSuccess = async (codeResponse: { code: string }) => {
    await loginFlow({
      code: codeResponse.code,
      newPermission: true,
    });
  };
  const onSwitchAccountSuccess = async (codeResponse: { code: string }) => {
    await loginFlow({
      code: codeResponse.code,
      switchAccount: true,
    });
  };
  const onCreateAccountSuccess = async (codeResponse: { code: string }) => {
    await loginFlow({
      code: codeResponse.code,
      createAccount: true,
    });
  };

  const onLoginError = useCallback(
    (errorResponse) => {
      logErrorLoginRequest(
        errorResponse.error,
        errorResponse.error_description,
      );
      if (
        errorResponse?.error_description?.includes('Cookies are not enabled')
      ) {
        setError({ type: 'incognito-mode' });
      } else {
        if (errorResponse?.error) {
          captureException(new Error(errorResponse.error));
        }
        setError({ type: 'google' });
      }
    },
    [setError],
  );

  const authCodeLoginArgs: UseGoogleLoginOptionsAuthCodeFlow = {
    flow: 'auth-code',
    onSuccess: onLoginSuccess,
    onError: onLoginError,
    include_granted_scopes: false,
    enable_serial_consent: true,
  } as UseGoogleLoginOptionsAuthCodeFlow;

  const callGoogleWithHandle = useCallback(
    (fn: DispatchWithoutAction) => () => {
      try {
        if (fn) fn();
      } catch (err: any) {
        logErrorLoginRequest('Error in useGoogleLogin', err.message);
        captureException(err);
        setError({ type: 'google' });
      }
    },
    [setError],
  );
  const onLogin = useGoogleLogin({
    ...authCodeLoginArgs,
  } as UseGoogleLoginOptionsAuthCodeFlow);

  const onSignUp = useGoogleLogin({
    ...authCodeLoginArgs,
    onSuccess: onCreateAccountSuccess,
  } as UseGoogleLoginOptionsAuthCodeFlow);

  const onSwitchAccount = useGoogleLogin({
    ...authCodeLoginArgs,
    onSuccess: onSwitchAccountSuccess,
  } as UseGoogleLoginOptionsAuthCodeFlow);

  const askForComposePermission = useGoogleLogin({
    ...authCodeLoginArgs,
    onSuccess: onPermissionSuccess,
    scope: COMPOSE_SCOPE,
    include_granted_scopes: true,
    enable_serial_consent: false,
  } as UseGoogleLoginOptionsAuthCodeFlow);

  const composePermissionGranted = async (): Promise<boolean> => {
    const scopes = await Google.getScopes();
    return !!scopes?.includes(COMPOSE_SCOPE);
  };

  const token = data?.token;

  const isLoading =
    (getUserStatus === QueryStatus.Loading && !token) ||
    refreshing ||
    createUserStatus === QueryStatus.Loading ||
    delegateLoading;

  useEffect(() => {
    if (!isLoading) {
      goToIntent();
    }
  }, [isLoading]);

  if (error && error.type === 'incognito-mode') {
    return <IncognitoMode />;
  }

  return (
    <AuthContext.Provider
      value={{
        token,
        clientId,
        onLogin: callGoogleWithHandle(onLogin),
        onSignUp: callGoogleWithHandle(onSignUp),
        logout,
        onSwitchAccount: callGoogleWithHandle(onSwitchAccount),
        getUserStatus,
        askForComposePermission: callGoogleWithHandle(askForComposePermission),
        composePermissionGranted,
        loading,
        refreshing,
        error,
      }}
    >
      <AnimatePresence>{isLoading && <LoadingScreen />}</AnimatePresence>
      {!isLoading ? children : null}
    </AuthContext.Provider>
  );
};
