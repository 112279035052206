import { captureException } from '@sentry/react';
import { getMeRollout } from 'data/requests/me';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import {
  getRegisterFlag,
  removeRegisterFlag,
  setDelegateId,
  setRegisterFlag,
} from 'utils/cookiesUtils';
import { pushSignUp } from 'utils/dataLayer';
import { Token } from './token';
import { ErrorType } from './types';

export const useDelegateLogin = (
  enabled: boolean | undefined | null,
  createUser: () => Promise<void>,
  setRefreshing: Dispatch<SetStateAction<boolean>>,
  setError: Dispatch<SetStateAction<ErrorType | null>>,
) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { data, refetch } = useQuery('meRollout', getMeRollout, {
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    staleTime: Number.POSITIVE_INFINITY,
    enabled: !!enabled,
    onSuccess: (responseData) => {
      if (responseData.isEligible === true) {
        const { backendIdToken } = Token.get();
        if (backendIdToken) {
          setDelegateId(backendIdToken);
        }
        window.location.replace(
          `${process.env.REACT_APP_MAIN_URL}${window.location.pathname}${window.location.search}`,
        );
      }
    },
  });

  const checkDelegate = useCallback(
    async (isRegister?: boolean) => {
      setLoading(true);
      const delegate = await refetch();
      if (delegate?.isEligible === true) {
        if (isRegister) {
          setRegisterFlag('register');
        }
      } else {
        setLoading(false);
      }

      return delegate?.isEligible === true;
    },
    [setLoading, refetch],
  );

  const delegateRegister = useCallback(async () => {
    if (getRegisterFlag() === 'register') {
      removeRegisterFlag();
      try {
        const delegate = await refetch();
        if (!delegate?.isRegistered) {
          await createUser();
          pushSignUp();
        }
      } catch (e) {
        captureException(e);
        setRefreshing(false);
        setError({ type: 'can-not-create-user' });
        Token.clear();
        return false;
      }
    }
    return true;
  }, [refetch, createUser, setError, setRefreshing]);

  return {
    delegateLoading:
      (enabled && (!data || data.isEligible === true)) || loading,
    loginEnabled: data?.isEligible === false,
    checkDelegate,
    delegateRegister,
  };
};
