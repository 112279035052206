import { client } from 'data/client';
import { Token } from 'data/context/AuthContext/token';
import { Input as CampaignInput } from 'model/campaign';
import { Tracking } from 'model/tracking';

type GetCampaignsTrackingDataResponse = {
  items: Pick<
    CampaignInput,
    'spreadsheetId' | 'sheetId' | 'totalEmails' | 'trackingInfos'
  >[];
};

export async function getMailMergesTrackingData(
  key: string,
  userId: string,
  request: any[],
) {
  const { idToken } = await Token.get();
  const { data } = await client.post<GetCampaignsTrackingDataResponse>(
    `/users/${userId}/campaigns-tracking-info`,
    {
      sheetKeys: request.map(({ spreadsheetId, sheetId }) => ({
        spreadsheetId,
        sheetId,
      })),
    },
    {
      headers: {
        'X-ID-Token': `Bearer ${idToken}`,
      },
    },
  );

  return (data?.items ?? []).reduce(
    (map, { spreadsheetId, sheetId, totalEmails, trackingInfos }) => ({
      ...map,
      [spreadsheetId]: {
        ...(map[spreadsheetId] ?? {}),
        [sheetId]: {
          totalEmails,
          trackingInfos,
        },
      },
    }),
    {} as Tracking,
  );
}
