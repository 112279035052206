import React from 'react';

import StatsCard from 'components/StatsCard';
import { from, useMediaQuery } from 'styles/media';

import { Switch, Route, Redirect } from 'react-router-dom';
import { Tab } from 'components/Tabs';
import testingVar from 'utils/testingVar';
import { useConnect } from './connect';
import {
  Container,
  Title,
  StatsContainer,
  Tabs,
  Header,
  NewButton,
  Sent,
  Tooltip,
  TooltipStyles,
  InfoIcon,
  EmailsSentText,
} from './styles';
import SentMailMerges from './Sent';
import ScheduledMailMerges from './Scheduled';
import { plurify } from '../../../utils/plurify';

const MailMerges = () => {
  const {
    url,
    path,
    campaigns,
    getCampaigns,
    setPage,
    isLoading,
    scheduledMailMerges,
    scheduledMergesStatus,
    stats,
  } = useConnect();

  const isMobile = !useMediaQuery(from.tablet);

  return (
    <>
      <Container>
        <Header>
          <Title>Mail Merges</Title>
          <NewButton
            href="https://support.yet-another-mail-merge.com/hc/en-us/articles/360020742280-Overview-of-sending-your-first-mail-merge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Sent />
            Quick tutorial
          </NewButton>
        </Header>
        <StatsContainer>
          <TooltipStyles />
          <StatsCard
            variant="merges"
            number={scheduledMailMerges?.length}
            text={`Mail ${plurify(
              scheduledMailMerges?.length,
              'merge',
            )} scheduled`}
          />
          <StatsCard
            variant="sent-this-month"
            number={stats?.totalEmailsSentThisMonth}
            content={
              <EmailsSentText>
                {plurify(stats?.totalEmailsSentThisMonth, 'Email')} sent this
                month
                <Tooltip title={<>Excluding form submission notifications.</>}>
                  <InfoIcon />
                </Tooltip>
              </EmailsSentText>
            }
          />
          <StatsCard
            variant="emails"
            number={stats?.totalEmailsSent}
            content={
              <EmailsSentText>
                {plurify(stats?.totalEmailsSent, 'Email')} sent all time
                <Tooltip title={<>Excluding form submission notifications.</>}>
                  <InfoIcon />
                </Tooltip>
              </EmailsSentText>
            }
          />
        </StatsContainer>
        <Tabs>
          <Tab
            data-test-id={testingVar('view-scheduled')}
            to={`${url}/scheduled`}
          >
            Scheduled
          </Tab>
          <Tab to={`${url}/sent`}>Sent</Tab>
        </Tabs>
        <Switch>
          <Route path={`${path}/sent`}>
            <SentMailMerges
              isLoading={isLoading}
              isMobile={isMobile}
              campaigns={campaigns}
              getCampaigns={getCampaigns}
              setPage={setPage}
            />
          </Route>
          <Route path={`${path}/scheduled`}>
            <ScheduledMailMerges
              isLoading={scheduledMergesStatus === 'loading'}
              isMobile={isMobile}
              scheduledCampaigns={scheduledMailMerges || []}
            />
          </Route>
          <Route path={path}>
            <Redirect to={`${path}/sent`} />
          </Route>
        </Switch>
      </Container>
    </>
  );
};

export default MailMerges;
