import styled, { css } from 'styled-components';

import { from } from 'styles/media';

import addAlpha from 'utils/addAlpha';
import { Props } from './types';

export const IconWrapper = styled.div`
  border-radius: 50%;
  height: 2.5rem;
  width: 2.5rem;
  flex-shrink: 0;
  margin-right: 0.75rem;
  padding: 0.6875rem;

  ${from.mobile} {
    height: 3.5rem;
    width: 3.5rem;
    margin-right: 1rem;
    padding: 1rem;
  }

  > svg {
    height: 100%;
    width: 100%;
  }
`;

export const StatsCardContainer = styled.div<{
  $variant: Props['variant'];
}>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.3125rem;
  display: flex;
  align-items: center;
  padding: 1rem 0.75rem;

  ${from.mobile} {
    padding: 1.25rem 1.25rem 1.25rem 1.5rem;
    align-items: unset;
  }

  ${({ $variant }) => {
    switch ($variant) {
      case 'merges':
      default:
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) =>
              addAlpha(theme.colors.green, 0.2)};
          }
        `;
      case 'sent-this-month':
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) =>
              addAlpha(theme.colors.blue700, 0.2)};
          }
        `;
      case 'emails':
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) =>
              addAlpha(theme.colors.purple, 0.2)};
          }
        `;
      case 'team':
        return css`
          ${IconWrapper} {
            background-color: ${({ theme }) =>
              addAlpha(theme.colors.yellow, 0.2)};
          }
        `;
    }
  }}
`;

export const Stat = styled.p`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;
`;

export const Number = styled.span`
  font-size: 1.25rem;
  font-weight: ${({ theme }) => theme.weights.semiBold};
  line-height: 1.75rem;
  margin-bottom: 0.125rem;

  ${from.mobile} {
    font-size: 1.5rem;
    line-height: 1.8125rem;
    margin-bottom: 0.3125rem;
  }
`;

export const NumberPlaceholder = styled.span`
  height: 1.875rem;
  margin-bottom: 0.125rem;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 0.375rem;

  ${from.mobile} {
    margin-bottom: 0.3125rem;
  }
`;

export const Text = styled.span`
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 0.75rem;
  line-height: 0.9375rem;
  letter-spacing: -0.0033em;
`;
