import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ReactComponent as DefaultIcon } from 'assets/images/icons/arrow-left.svg';
import DefaultCountrySelect from 'components/CountrySelect';
import DefaultInput from 'components/Input';
import DefaultOrderSummary from 'components/OrderSummary';
import DefaultStripeStamp from 'components/StripeStamp';
import { from } from 'styles/media';
import addAlpha from 'utils/addAlpha';
import DefaultBillingMessage from 'components/BillingMessage';
import DefaultLoader from 'components/Loader';
import DefaultRadioInput from './RadioInput';

export const Container = styled.div`
  --maxWidth: 58.5rem;
  margin: 0 auto;
  padding: 2.5rem 1rem;
  display: grid;
  grid-template-columns: 1rem 1fr 1rem;
  color: ${({ theme }) => theme.colors.gray3};

  & > * {
    grid-column: 2;
  }

  ${from.tablet} {
    grid-template-columns: auto var(--maxWidth) auto;
  }

  ${from.laptop} {
    padding-top: 2.1875rem;
  }
`;

export const BillingMessage = styled(DefaultBillingMessage)`
  margin-bottom: 3.375rem;
`;

export const Loader = styled(DefaultLoader)`
  padding: 0;
  display: flex;
  justify-content: center;

  svg {
    color: ${({ theme }) => addAlpha(theme.colors.white, 0.85)};
  }
`;

export const Header = styled.div`
  align-items: center;
  display: flex;
`;

export const Back = styled(Link)`
  color: inherit;
  display: inline-flex;
  margin-right: 0.75rem;
  text-decoration: none;
`;

export const Icon = styled(DefaultIcon)`
  color: inherit;
  height: 1.5rem;
  width: 1.5rem;
`;

export const Title = styled.h6`
  color: ${({ theme }) => theme.colors.gray4};
  font-size: 1.5rem;
  line-height: 1.875rem;
`;

export const SubtitleMessage = styled.p`
  margin-top: 13px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: ${({ theme }) => theme.colors.gray3};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${from.tablet} {
    flex-direction: row;
  }
`;

export const FormPlaceholder = styled.div`
  height: 41.25rem;
`;

export const OrderSummaryPlaceholder = styled.div`
  height: 16rem;
  width: 100%;
  border-radius: 0.3125rem;
  background-color: ${({ theme }) => theme.colors.gray2};
`;

export const Column = styled.div<{ $isLoading?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  ${from.tablet} {
    margin-bottom: 0;

    &:nth-child(1) {
      background: ${({ theme, $isLoading }) =>
        $isLoading ? theme.colors.gray2 : theme.colors.white};
      border-radius: 0.3125rem;
      display: flex;
      flex: 1.5;
      flex-direction: column;
      padding: 1.5rem;
    }

    &:nth-child(2) {
      flex: 1;
      padding-left: 1.5rem;
    }
  }
`;

export const CountrySelect = styled(DefaultCountrySelect)`
  flex: 1;
`;

export const Input = styled(DefaultInput)`
  color: inherit;
  flex: 1;
`;

export const RadioInput = styled(DefaultRadioInput)`
  flex: 1;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    margin-bottom: 0.625rem;
  }

  & > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  ${from.tablet} {
    flex-direction: row;

    & > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 1.5rem;
    }
  }
`;

export const ColumnedRow = styled(Row)`
  gap: 0 1.5rem;
  ${from.mobile} {
    gap: 1.5rem 0;
  }
`;

export const OrderSummary = styled(DefaultOrderSummary)`
  margin-bottom: 1.5rem;
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.orange600};
  border: none;
  border-radius: 0.3125rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: inherit;
  font-size: inherit;
  margin-bottom: 1.5rem;
  padding: 0.625rem 1rem;

  &:disabled {
    background-color: ${({ theme }) => addAlpha(theme.colors.gray3, 0.5)};
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

export const StripeStamp = styled(DefaultStripeStamp)`
  align-self: center;
`;

export const TermsAndServicesText = styled.p`
  margin-top: 12px;
  text-align: center;
  color: #77716c;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 20.8px */
  letter-spacing: -0.13px;
`;
