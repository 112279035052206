import { parseISO } from 'date-fns';
import { normalizeTrackingData, TrackingData } from './trackingData';

export type Input = {
  id: string;
  spreadsheetId: string;
  sheetId: string;
  lastTimeUsed: string;
  trackingEnabled: boolean;
  emailSubject: string;
  spreadsheetName: string;
  sheetName: string;
  labelName: string;
  labelId: string;
  totalEmails?: number;
  trackingInfos?: TrackingData;
  hasTrackingData?: boolean;
};

export const normalizeCampaign = (input: Input, excludeTracking?: boolean) => {
  const data = {
    ...input,
    title: `${input.spreadsheetName} - ${input.sheetName}`,
    lastTimeUsed: parseISO(input.lastTimeUsed).getTime(),
    sheetLink: `https://docs.google.com/spreadsheets/d/${input.sheetId}`,
    spreadsheetLink: `https://docs.google.com/spreadsheets/d/${input.spreadsheetId}/edit#gid=${input.sheetId}`,
    trackingInfos: normalizeTrackingData(input.trackingInfos),
    hasTrackingData: !excludeTracking,
  };

  if (input.totalEmails) {
    data.trackingInfos.sent = input.totalEmails;
  }

  return data;
};

export type Campaign = ReturnType<typeof normalizeCampaign>;
