export function getBillingIntervalFeatureFlag() {
  const flagToSplit = process.env.REACT_APP_MONTHLY_BILLING_FEATURE_FLAG;
  return flagToSplit === undefined ? ['NONE'] : flagToSplit.split(',');
}

export function getDefaultBillingCycleFeatureFlag() {
  return process.env.REACT_APP_DEFAULT_BILLING_CYCLE_FEATURE_FLAG || 'YEARLY';
}

export function canMonthlyForPlan(plan: string, seats: number = 0) {
  const flagArray = getBillingIntervalFeatureFlag();
  const planWithSeats = plan === 'TEAM' ? `TEAM_${seats}` : plan;
  return flagArray.includes(planWithSeats) || flagArray.includes('ALL');
}

export function canMonthlyBillingInterval(
  plan: string,
  billingInterval: string,
  seats: number = 0,
) {
  return canMonthlyForPlan(plan, seats) ? billingInterval : 'YEARLY';
}
