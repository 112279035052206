import { Option } from './types';

export const options: Option[] = [
  {
    label: 'Billed monthly',
    value: 'MONTHLY',
  },
  {
    label: 'Billed annually',
    value: 'YEARLY',
  },
];
