import { getUserIdFromEmail } from '../userUtils';
import { NewPlan, Plan } from '../../model/plan';
import { logToStackDriver } from '../logToStackdriver';

export const logCheckoutPageOpened = (
  userEmail: string | undefined,
  plan: NewPlan,
  seats: number | undefined,
  initialUrl: string,
  currentPlan: Plan,
) => {
  const isPaid = currentPlan === 'PAID' || currentPlan === 'DOMAIN_PAID';
  logToStackDriver({
    user_id: userEmail ? getUserIdFromEmail(userEmail) : undefined,
    timestamp: Date.now(),
    message: 'User opens the billing details form on the webapp',
    event_type: 'checkout_open_billing_page',
    selected_plan: plan,
    selected_team_users: seats,
    initial_url: initialUrl,
    is_paid: isPaid,
  });
};

export const logCheckoutPageOpenedError = (
  userEmail: string | undefined,
  error: string,
  plan: NewPlan,
  seats: number | undefined,
) =>
  logToStackDriver({
    user_id: userEmail ? getUserIdFromEmail(userEmail) : undefined,
    timestamp: Date.now(),
    message: 'Error while opening checkout page',
    event_type: 'checkout_open_billing_page_error',
    selected_plan: plan,
    selected_team_users: seats,
    error,
  });

export const logClickStripeCheckout = (userEmail: string | undefined) =>
  logToStackDriver({
    user_id: userEmail ? getUserIdFromEmail(userEmail) : undefined,
    timestamp: Date.now(),
    message:
      'User clicks on "Go to checkout" in the billing form on the webapp',
    event_type: 'checkout_click_start_checkout',
  });

export const logStripeCheckoutRedirectSuccess = (
  userEmail: string | undefined,
  hasStripeLibrary: boolean,
) =>
  logToStackDriver({
    user_id: userEmail ? getUserIdFromEmail(userEmail) : undefined,
    timestamp: Date.now(),
    message:
      'The stripe checkout session is created and the user is about to be redirected (webapp)',
    event_type: 'checkout_redirect_stripe_checkout',
    redirect_using: hasStripeLibrary ? 'sessionId' : 'url',
  });

export const logStripeCheckoutRedirectError = (
  userEmail: string | undefined,
  error: string,
) =>
  logToStackDriver({
    user_id: userEmail ? getUserIdFromEmail(userEmail) : undefined,
    timestamp: Date.now(),
    message: 'Error during stripe checkout generation',
    event_type: 'checkout_redirect_stripe_checkout_error',
    error,
  });

export const logStripeCheckoutSuccess = (
  userEmail: string | undefined,
  typeOfSuccess: NewPlan,
  stripeSessionId?: string,
) =>
  logToStackDriver({
    user_id: userEmail ? getUserIdFromEmail(userEmail) : undefined,
    timestamp: Date.now(),
    message: 'User lands on purchase confirmation page on the webapp',
    event_type: 'checkout_purchase_confirmation',
    plan: typeOfSuccess,
    stripe_session_id: stripeSessionId,
  });

export const logStripeCheckoutError = (
  userEmail: string | undefined,
  typeOfError: string,
) =>
  logToStackDriver({
    user_id: userEmail ? getUserIdFromEmail(userEmail) : undefined,
    timestamp: Date.now(),
    message: 'Error after Stripe checkout',
    event_type: 'checkout_purchase_error',
    error: typeOfError,
  });
